import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Homepage from "../pages/Home.page";
import Homelayout from "../layouts/Root.layout";
import SearchLayout from "../layouts/Search.layout";
import FlightSearchPage from "../pages/FlightSearch.page";
import ReviewLayout from "../layouts/Review,layout";
import ReviewPage from "../pages/FlightDetails.page";
import TicketDetailsPage from "../pages/TicketDetails.page";
// import TrainsPage from "../pages/Trains.page";
import NotFoundPage from "../pages/NotFound.page";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Homelayout />,
    children: [
      {
        path: "",
        element: <Homepage />,
      },
    ],
  },
  {
    path: "/search/:tripType",
    element: <SearchLayout />,
    children: [
      {
        path: "",
        element: <FlightSearchPage />,
      },
    ],
  },
  {
    path: "/flightdetails",
    element: <ReviewLayout />,
    children: [
      {
        path: "",
        element: <ReviewPage />,
      },
    ],
  },
  {
    path: "/ticketdetails",
    element: <ReviewLayout />,
    children: [
      {
        path: "",
        element: <TicketDetailsPage />,
      },
    ],
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
]);

export default Router;
