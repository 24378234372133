import React, { useState } from "react";
import { NO_DATA } from "../../utils/images";
import { DataFilter } from "../../utils/filterSystem";
import Locations from "../../csvjson.json";
import { useNavigate } from "react-router-dom";
import { FlightJourneyDetailsI } from "../../types/flightSearchReducer.types";
import { useAppDispatch } from "../../store";
import { fetchFlightPrecisePricing } from "../../store/reducers/flightSearch.reducer";
import { AirlineFilterFunction } from "../../utils/ExtraFunction";

interface Prop {
  solution?: any;
  flights?: any;
  segments?: any;
}

const RoundTripAccordin: React.FC<Prop> = ({ solution, flights, segments }) => {
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [detailsNavTitle, setDetailsNavTitle] = useState("Details");
  const Navigate = useNavigate();
  const dispatch = useAppDispatch();

  const totalTimeFromDeparture = flights.filter(
    (id: { flightId: any }) => id.flightId === solution.journeys.journey_0[0]
  )[0].journeyTime;
  const ifStopInDepartureFlight = flights.filter(
    (id: { flightId: any }) => id.flightId === solution.journeys.journey_0[0]
  )[0].segmengtIds;

  const totalTimeFromReturn = flights.filter(
    (id: { flightId: any }) => id.flightId === solution.journeys.journey_1[0]
  )[0].journeyTime;
  const ifStopInReturnFlight = flights.filter(
    (id: { flightId: any }) => id.flightId === solution.journeys.journey_1[0]
  )[0].segmengtIds;

  const formatTime = (time: number) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${hours}H ${minutes}M`;
  };

  const baggageWeight: { BaggageW: string; CarryW: string }[] = [];
  solution.baggageMap.ADT.map((item: any) =>
    baggageWeight.push({
      BaggageW: item.baggageWeight,
      CarryW: item.carryOnWeight,
    })
  );

  const segmentDeparture = DataFilter(
    solution.journeys.journey_0[0],
    flights,
    segments
  );

  const segmentReturn = DataFilter(
    solution.journeys.journey_1[0],
    flights,
    segments
  );

  const allSegment = segmentDeparture.concat(segmentReturn);

  const segment1Time: string[] = [];
  const segment1State: string[] = [];
  const segment2Time: string[] = [];
  const segment2State: string[] = [];

  segmentDeparture.map((item) => {
    segment1Time.push(item.strDepartureTime, item.strArrivalTime);
    segment1State.push(item.departure, item.arrival);
    return 0;
  });
  segmentReturn.map((item) => {
    segment2Time.push(item.strDepartureTime, item.strArrivalTime);
    segment2State.push(item.departure, item.arrival);
    return 0;
  });

  const fare = (solution.adtFare + solution.adtTax).toFixed(2);

  let Departure: FlightJourneyDetailsI[] = [];
  let Return: {
    airline: string;
    flightNum: string;
    equipment: string;
    cabinClass: string;
    bookingCode: string;
    availabilityCount: number;
    departure: string;
    arrival: string;
    departureTerminal: string;
    arrivalTerminal: string;
    flightTime: number;
    stayTime: null;
    codeShare: string;
    opFltNo: null;
    opFltAirline: string;
    stopover: null;
    fareBasis: string;
    departureDate: string;
    departureTime: string;
    arrivalDate: string;
    arrivalTime: string;
  }[] = [];

  const dataToArray = () => {
    segmentDeparture.map((item) => {
      let obj = {
        airline: item.airline,
        flightNum: item.flightNum,
        equipment: item.equipment,
        cabinClass: item.cabinClass,
        bookingCode: item.bookingCode,
        availabilityCount: item.availabilityCount,
        departure: item.departure,
        arrival: item.arrival,
        departureTerminal: item.departureTerminal,
        arrivalTerminal: item.arrivalTerminal,
        flightTime: item.flightTime,
        stayTime: item.stayTime,
        codeShare: item.codeShare,
        opFltNo: item.opFltNo,
        opFltAirline: item.opFltAirline,
        stopover: item.stopover,
        fareBasis: item.fareBasis,
        departureDate: item.strDepartureDate,
        departureTime: item.strDepartureTime,
        arrivalDate: item.strArrivalDate,
        arrivalTime: item.strArrivalTime,
      };
      Departure.push(obj);
      return 0;
    });
    segmentReturn.map((item) => {
      let obj = {
        airline: item.airline,
        flightNum: item.flightNum,
        equipment: item.equipment,
        cabinClass: item.cabinClass,
        bookingCode: item.bookingCode,
        availabilityCount: item.availabilityCount,
        departure: item.departure,
        arrival: item.arrival,
        departureTerminal: item.departureTerminal,
        arrivalTerminal: item.arrivalTerminal,
        flightTime: item.flightTime,
        stayTime: item.stayTime,
        codeShare: item.codeShare,
        opFltNo: item.opFltNo,
        opFltAirline: item.opFltAirline,
        stopover: item.stopover,
        fareBasis: item.fareBasis,
        departureDate: item.strDepartureDate,
        departureTime: item.strDepartureTime,
        arrivalDate: item.strArrivalDate,
        arrivalTime: item.strArrivalTime,
      };
      Return.push(obj);
      return 0;
    });
  };

  dataToArray();

  const handleClick = () => {
    const precisePricingRequest = {
      journeys: {
        journey_0: Departure,
        journey_1: Return,
      },
      adults: solution.adults,
      children: solution.children,
      infants: solution.infants,
      solutionId: solution.solutionId,
      cabin: "",
    };
    // #Storing Data in local Storage
    localStorage.setItem(
      "precisePricing",
      JSON.stringify(precisePricingRequest)
    );

    dispatch(
      fetchFlightPrecisePricing({ ...precisePricingRequest, key: "pricing" })
    );
    Navigate("/flightdetails");
  };

  return (
    <div className="bg-white h-fit w-full p-2 my-4">
      <div className="flex flex-col md:flex-row gap-6 md:gap-0 items-center w-full justify-between">
        <div className="flex items-center gap-10">
          <div className="flex items-center gap-2">
            <div
              className="bg-cover bg-no-repeat bg-center w-[2.5rem] h-[2.5rem]"
              style={{
                backgroundImage: `url(${
                  solution.platingCarrier
                    ? AirlineFilterFunction(solution.platingCarrier).image_link
                    : NO_DATA
                })`,
              }}
            ></div>
            <div>
              <h2 className="text-base font-bold">
                {solution.platingCarrier
                  ? AirlineFilterFunction(solution.platingCarrier).Company_Name
                  : "IndiGo"}
              </h2>
              <p className="text-sm font-semibold">
                {solution.platingCarrier
                  ? AirlineFilterFunction(solution.platingCarrier).AirLine_code
                  : "7E"}{" "}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div
              className="bg-cover bg-no-repeat bg-center w-[2.5rem] h-[2.5rem]"
              style={{
                backgroundImage: `url(${
                  solution.platingCarrier
                    ? AirlineFilterFunction(solution.platingCarrier).image_link
                    : NO_DATA
                })`,
              }}
            ></div>
            <div>
              <h2 className="text-base font-bold">
                {solution.platingCarrier
                  ? AirlineFilterFunction(solution.platingCarrier).Company_Name
                  : "IndiGo"}
              </h2>
              <p className="text-sm font-semibold">
                {solution.platingCarrier
                  ? AirlineFilterFunction(solution.platingCarrier).AirLine_code
                  : "7E"}{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-5 w-fit">
          <h2 className="text-lg font-bold flex gap-1 items-center justify-end w-fit">
            <span>$</span>
            {fare ? fare : 4000}
          </h2>
          <button
            className="flex items-center justify-center px-6 py-2 bg-[#ec2027] text-white rounded-full text-xs lg:text-base shadow-md font-semibold"
            onClick={handleClick}
          >
            Book Now
          </button>
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row items-center gap-6 md:gap-3 mt-[2rem]">
        <div className="w-full md:w-[50%]">
          <h2 className="text-sm font-bold uppercase flex items-end gap-1">
            <span>Depart</span>
            <span className="text-base">
              {segmentDeparture[0].strDepartureDate}
            </span>{" "}
            Indigo
          </h2>
          <div className="flex items-center justify-between h-[4rem] bg-[#F4F4F4] shadow-md border-[1px] border-black/20 px-4">
            <div>
              <h2 className="font-bold">
                {segment1Time ? segment1Time[0] : "08:30"}
              </h2>
              <p className="text-sm font-semibold">
                {segment1State ? segment1State[0] : "New Delhi"}
              </p>
            </div>
            <div className="flex flex-col justify-center">
              <span className="font-bold text-center leading-5">
                {totalTimeFromDeparture
                  ? formatTime(totalTimeFromDeparture)
                  : "02h 15m"}
              </span>
              <hr className="bg-red-600 w-[5rem] h-[5px] rounded-full" />
              <p className="text-center text-xs font-bold">
                {ifStopInDepartureFlight.length === 1
                  ? "Non stop"
                  : `${ifStopInDepartureFlight.length - 1} Stop`}
              </p>
            </div>
            <div>
              <h2 className="font-bold">
                {segment1Time ? segment1Time[segment1Time.length - 1] : "08:30"}
              </h2>
              <p className="text-sm font-semibold">
                {segment1State
                  ? segment1State[segment1State.length - 1]
                  : "New Delhi"}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-[50%]">
          <h2 className="text-sm font-bold uppercase flex items-center gap-1">
            <span>Depart</span>
            <span>{segmentReturn[0].strDepartureDate}</span> Indigo
          </h2>
          <div className="flex items-center justify-between h-[4rem] bg-[#F4F4F4] shadow-md border-[1px] border-black/20 px-4">
            <div>
              <h2 className="font-bold">
                {segment2Time ? segment2Time[0] : "08:30"}
              </h2>
              <p className="text-sm font-semibold">
                {segment2State ? segment2State[0] : "New Delhi"}
              </p>
            </div>
            <div className="flex flex-col justify-center">
              <span className="font-bold text-center leading-5">
                {totalTimeFromReturn
                  ? formatTime(totalTimeFromReturn)
                  : "02h 15m"}
              </span>
              <hr className="bg-red-600 w-[5rem] h-[5px] rounded-full" />
              <p className="text-center text-xs font-bold">
                {ifStopInReturnFlight.length === 1
                  ? "Non stop"
                  : `${ifStopInReturnFlight.length - 1} Stop`}
              </p>
            </div>
            <div>
              <h2 className="font-bold">
                {segment2Time ? segment2Time[segment2Time.length - 1] : "08:30"}
              </h2>
              <p className="text-sm font-semibold">
                {segment2State
                  ? segment2State[segment2State.length - 1]
                  : "New Delhi"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-end">
        <span
          className="cursor-pointer block mt-3 hover:text-[#ec2027] font-semibold"
          onClick={() => setIsViewDetails(!isViewDetails)}
        >
          {!isViewDetails ? "View Flight Details" : "Hide Flight Details"}
        </span>
      </div>
      <div
        className={
          isViewDetails
            ? "w-full h-auto max-h-fit transition-all duration-300 mt-4 bg-white p-2"
            : "w-full h-0 max-h-0 overflow-hidden transition-all duration-300 bg-white p-2"
        }
      >
        <div
          className={
            isViewDetails
              ? "w-full h-fit flex items-center justify-between shadow-md border-[1px] border-black/10 px-4 rounded-md gap-2"
              : "hidden duration-100"
          }
        >
          <div
            className={
              detailsNavTitle === "Details"
                ? "h-full md:w-[25%] text-xs bg-[#ec2027] text-white px-2 py-1 rounded-lg font-semibold text-center cursor-pointer "
                : " cursor-pointer h-full md:w-[25%] hover:bg-[#ec2027] hover:text-white px-2 py-1 rounded-lg font-semibold text-center text-xs"
            }
            onClick={() => setDetailsNavTitle("Details")}
          >
            Flight Details
          </div>
          <div
            className={
              detailsNavTitle === "Summary"
                ? "h-full md:w-[25%] text-xs bg-[#ec2027] text-white px-2 py-1 rounded-lg font-semibold text-center cursor-pointer "
                : " cursor-pointer h-full md:w-[25%] hover:bg-[#ec2027] hover:text-white px-2 py-1 rounded-lg font-semibold text-center text-xs"
            }
            onClick={() => setDetailsNavTitle("Summary")}
          >
            Fare Summary
          </div>
          <div
            className={
              detailsNavTitle === "Info"
                ? "h-full md:w-[25%] text-xs bg-[#ec2027] text-white px-2 py-1 rounded-lg font-semibold text-center cursor-pointer "
                : " cursor-pointer h-full md:w-[25%] hover:bg-[#ec2027] hover:text-white px-2 py-1 rounded-lg font-semibold text-center text-xs"
            }
            onClick={() => setDetailsNavTitle("Info")}
          >
            Baggage Info
          </div>
          {/* <div className={filter === 'DateChange' ? 'h-full w-[25%] bg-[#ec2027] text-white px-2 py-1 rounded-lg font-semibold text-center' : 'h-full w-[25%] hover:bg-[#ec2027] hover:text-white px-2 py-1 rounded-lg font-semibold text-center'} onClick={() => setFilter('DateChange')} >Date Change</div> */}
        </div>
        <div className="w-full border-b-[1px] border-x-[1px] border-black mt-4 md:mt-3">
          {detailsNavTitle === "Details" &&
            allSegment.map((item: any, index) => (
              <div key={index}>
                <div className="flex items-center h-[2rem] font-bold px-2 border-y-[1px] border-black">
                  {item.departure ? item.departure : "New Delhi"} to{" "}
                  {item.arrival ? item.arrival : "Mumbai"},{" "}
                  {item.strDepartureDate ? item.strDepartureDate : `31 Aug`}
                </div>
                <div className="my-3 px-2">
                  <div className="flex items-center gap-1">
                    <div
                      className="bg-cover bg-no-repeat bg-center w-[2rem] h-[2rem]"
                      style={{
                        backgroundImage: `url(${
                          item
                            ? AirlineFilterFunction(item.airline).image_link
                            : NO_DATA
                        })`,
                      }}
                    ></div>
                    <h2 className="text-base font-bold">
                      {item
                        ? AirlineFilterFunction(item.airline).Company_Name
                        : "No airline name"}
                    </h2>
                    <p className="text-base font-semibold">
                      {item.airline ? item.airline : `6E`} |{" "}
                      {item.flightNum ? item.flightNum : 6114}
                    </p>
                  </div>
                  <div className="flex flex-col md:flex-row items-start mt-4 w-full gap-4 md:gap-0">
                    <div className="flex items-start w-full md:w-[60%] lg:w-[50%] justify-between">
                      <div className="flex flex-col gap-3 w-[25%]">
                        <div>
                          <span className="text-base font-bold">
                            {item.strDepartureTime
                              ? item.strDepartureTime
                              : `22:15`}
                          </span>
                          <p className="text-sm font-bold">
                            {item.strDepartureDate
                              ? item.strDepartureDate
                              : "Sat, 31 Aug 24"}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">
                            {item.departureTerminal
                              ? `Terminal T${item.departureTerminal}`
                              : "No Information"}
                          </p>
                          <p className="text-sm">
                            {item.departure
                              ? Locations.filter(
                                  (obj) => obj.IATA === item.departure
                                )[0].Location
                              : `New Delhi, India`}
                          </p>
                        </div>
                      </div>
                      <div className="w-fit">
                        <h2 className="text-center text-xs lg:text-base font-bold">
                          {item.flightTime
                            ? formatTime(item.flightTime)
                            : "02h 10m"}
                        </h2>
                        <hr className="lg:w-[5rem] h-[5px] rounded-full bg-[#ec2027]"></hr>
                      </div>
                      <div className="flex flex-col gap-3 w-[25%]">
                        <div>
                          <span className="text-base font-bold">
                            {item.strArrivalTime
                              ? item.strArrivalTime
                              : `00:25`}
                          </span>
                          <p className="text-sm font-bold">
                            {item.strArrivalDate
                              ? item.strArrivalDate
                              : "Sun, 1 sep 24"}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm">
                            {item.arrivalTerminal
                              ? `Terminal T${item.arrivalTerminal}`
                              : "No Information"}
                          </p>
                          <p className="text-sm">
                            {item.departure
                              ? Locations.filter(
                                  (obj) => obj.IATA === item.arrival
                                )[0].Location
                              : `New Delhi, India`}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full md:w-[40%] lg:w-[50%] flex flex-col items-center justify-center">
                      <div className="flex items-start justify-start gap-2">
                        <div className="text-xs lg:text-base font-bold uppercase">
                          Check-in Baggage:
                        </div>
                        <div className="text-xs lg:text-base font-bold uppercase">
                          {baggageWeight && baggageWeight[0].BaggageW
                            ? baggageWeight[0].BaggageW
                            : "No Information"}
                        </div>
                      </div>
                      <div className="flex items-start justify-start gap-2">
                        <div className="text-xs lg:text-base font-bold uppercase">
                          Cabin Baggage:
                        </div>
                        <div className="text-xs lg:text-base font-bold uppercase">
                          {baggageWeight ? baggageWeight[0].CarryW : "15WK"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {detailsNavTitle === "Summary" && (
            <>
              <div className="flex items-center h-[2rem] px-2 border-y-[1px] border-black">
                Fare Breakup
              </div>
              <div className="my-3 px-2">
                <div className="flex items-start w-[60%] md:w-[30%] justify-between">
                  <div className="flex flex-col gap-3">
                    <span className="text-base font-bold">Total :-</span>
                    <p className="text-base font-bold">Base Fare :-</p>
                    <p className="text-base font-bold">Surcharges :-</p>
                  </div>
                  <div className="flex flex-col gap-3">
                    <span className="text-base font-bold">
                      ${" "}
                      {(
                        solution.adtFare +
                        solution.adtTax +
                        solution.chdFare +
                        solution.chdTax +
                        solution.qCharge +
                        solution.tktFee +
                        solution.platformServiceFee
                      ).toFixed(2)}
                    </span>
                    <p className="text-base font-bold">
                      $ {(solution.adtFare + solution.chdFare).toFixed(2)}
                    </p>
                    <p className="text-base font-bold">
                      ${" "}
                      {(
                        solution.adtTax +
                        solution.chdTax +
                        solution.qCharge +
                        solution.tktFee +
                        solution.platformServiceFee
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          {detailsNavTitle === "Info" && (
            <>
              <div className="flex items-center h-[2rem] px-2 border-b-[1px] border-black">
                Baggage Allowance and Charges
              </div>
              <div className="my-3 px-2">
                <div className="flex items-center justify-start gap-4">
                  <div className="text-base font-bold">Check In Baggage:</div>
                  <div className="text-base font-bold">
                    {" "}
                    {baggageWeight ? baggageWeight[0].BaggageW : "15WK"}
                  </div>
                </div>
                <div className="flex items-center justify-start gap-4">
                  <div className="text-base font-bold">Cabin Baggage:</div>
                  <div className="text-base font-bold">
                    {baggageWeight ? baggageWeight[0].CarryW : "7WK"}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoundTripAccordin;
