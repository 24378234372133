import Airline from "../Airline.json";
export const daysName = (date: any) => {
  if (date.day() === 0) {
    return "Sunday";
  } else if (date.day() === 1) {
    return "Monday";
  } else if (date.day() === 2) {
    return "Tuesday";
  } else if (date.day() === 3) {
    return "Wednesday";
  } else if (date.day() === 4) {
    return "Thursday";
  } else if (date.day() === 5) {
    return "Friday";
  } else if (date.day() === 6) {
    return "Saturday";
  }
};

export const monthsName = (date: any) => {
  if (date.month() === 0) {
    return "JAN";
  } else if (date.month() === 1) {
    return "FEB";
  } else if (date.month() === 2) {
    return "MAR";
  } else if (date.month() === 3) {
    return "APR";
  } else if (date.month() === 4) {
    return "MAY";
  } else if (date.month() === 5) {
    return "JUN";
  } else if (date.month() === 6) {
    return "JUL";
  } else if (date.month() === 7) {
    return "AUG";
  } else if (date.month() === 8) {
    return "SEP";
  } else if (date.month() === 9) {
    return "OCT";
  } else if (date.month() === 10) {
    return "NOV";
  } else if (date.month() === 11) {
    return "DEC";
  }
};

export const formatTime = (time: number) => {
  const hours = Math.floor(time / 60);
  const minutes = time % 60;
  return `${hours}h ${minutes}m`;
};

export const AirlineFilterFunction = (AirlineName: string) => {
  return Airline.filter((airline) => AirlineName === airline.AirLine_code)[0];
};

export const BubbleEvent = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

const reqBody = (
  userInfo: any,
  paymentInfo: any,
  address: any,
  contact: any,
  searchParams: any
) => {
  const userArr: any = [];
  userInfo.map((item: any) =>
    userArr.push({
      firstName: item.firstName,
      lastName: item.lastName,
      nationality: item.nationality,
      DOB: item.birthday,
      gender: item.sex,
      userType: item.psgType as string,
    })
  );
  return {
    searchParams: {
      destination: searchParams.destination,
      origin: searchParams.origin,
      departureDate: searchParams.departureDate,
      returnDate: searchParams.returnDate,
      totalTravelers: searchParams.totalTravelers,
      adults: searchParams.adults,
      children: searchParams.children,
      infants: searchParams.infants,
      tripType: searchParams.tripType,
      cabinClass: searchParams.cabinClass,
    },
    Passengers: userArr,
    payment_method: paymentInfo.cardType,
    credit_debit_card_number: paymentInfo.cardNum,
    expiration_date: paymentInfo.cardExpiredDate,
    CV_number: paymentInfo.cvv,
    card_holder_name: paymentInfo.cardHolderName,
    country: address.country,
    state: address.state,
    address: address.Address,
    city: address.city,
    pinCode: address.zip,
    email: contact.retypeEmail,
    Phone_number: contact.billingPhone,
    alt_Phone_number: contact.mobile,
  };
};

export const AddUserDetails = async (
  userInfo: any,
  paymentInfo: any,
  address: any,
  contact: any,
  searchParams: any
) => {
  const Message = await fetch(
    process.env.REACT_APP_DataCollectingRoute as string,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        reqBody(userInfo, paymentInfo, address, contact, searchParams)
      ),
    }
  );

  const response = await Message.json();
  return response.message;
};
