import React, { useEffect, useRef, useState } from "react";
import { Banner } from "../../utils/images";
import HeroNav from "./HeroNav.component";
import { useNavigate } from "react-router-dom";
import { MdCompareArrows } from "react-icons/md";
import { FaAngleDown } from "react-icons/fa";
import { Show } from "../../utils/Show";
import { notifyError, notifySuccess } from "../../utils/notifications";
import Calendar from "../Calender.component";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { daysName, monthsName } from "../../utils/ExtraFunction";
import SearchPathComponent from "./SearchPath.component";
import SearchButton from "../Buttons/Search.Button";
import TripTypeComponent from "./HeroSection/TripType.component";
import CityFieldInputComponent from "./HeroSection/CityFieldInput.component";
// import SelectNumberOfTravellersComponent from "./HeroSection/SelectNumberOfTravellers.component";
import { useSelector } from "react-redux";
import {
  SearchAirLegsI,
  SearchParamsI,
  tripTypeT,
} from "../../types/flightSearchReducer.types";
import {
  fetchFlights,
  setFlightSearchParams,
  setIsCabinClassOptionsVisible,
  setIsDepartureCalender,
  setIsFromFieldDropdown,
  setIsReturnCalender,
  setIsToFieldDropdown,
  TravelersPopupVisible,
  updateFlightSearchParams,
} from "../../store/reducers/flightSearch.reducer";
import { useAppDispatch } from "../../store";
import Calendar2 from "../Calender2.component";

export interface InputState {
  Location: string;
  airportName: string;
  IATA: string;
}

const HeroSection = () => {
  const Navigation = useNavigate();
  const state = useSelector((state: any) => state.flights);
  const dispatch = useAppDispatch();
  const [tripType, setTripType] = useState<tripTypeT>(
    state.searchParams.tripType
  );
  // const [fromFieldDropdown, setFromFieldDropdown] = useState<boolean>(false);
  // const [toFieldDropdown, setToFieldDropdown] = useState<boolean>(false);
  const [isTravellersClass, setIsTravellersClass] = useState<boolean>(false);
  // const [con, setCon] = useState(false);
  const show = Show();
  const [fromField, setFromField] = useState<InputState>({
    Location: "",
    airportName: "",
    IATA: "",
  });
  const [toField, setToField] = useState<InputState>({
    Location: "",
    airportName: "",
    IATA: "",
  });
  const [classes, setClasses] = useState(state.searchParams.cabinClass);
  const [adult, setAdult] = useState(state.searchParams.adults);
  const [children, setChildren] = useState(state.searchParams.children);
  const [infant, setInfant] = useState(state.searchParams.infants);
  // const [isDepartureCalender, setIsDepartureCalender] = useState(false);
  // const [isReturnCalender, setIsReturnCalender] = useState(false);
  const [departureDate, setDepartureDate] = useState(dayjs.extend(weekday));
  const [returnDate, setReturnDate] = useState(dayjs.extend(weekday));
  const InputRef = useRef<HTMLInputElement>(null);

  const handleChange = (
    destinationType: "from" | "to",
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    if (destinationType === "from") {
      dispatch(setIsFromFieldDropdown(!state.isFromFieldDropdown));
      dispatch(setIsDepartureCalender(false));
      dispatch(setIsCabinClassOptionsVisible(false));
      dispatch(setIsReturnCalender(false));
      dispatch(TravelersPopupVisible(false));
      dispatch(setIsToFieldDropdown(false));
    } else if (destinationType === "to") {
      dispatch(setIsToFieldDropdown(!state.isToFieldDropdown));
      dispatch(setIsReturnCalender(false));
      dispatch(setIsDepartureCalender(false));
      dispatch(setIsCabinClassOptionsVisible(false));
      dispatch(TravelersPopupVisible(false));
      dispatch(setIsFromFieldDropdown(false));
    }
  };

  useEffect(() => {
    if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
      dispatch(
        updateFlightSearchParams({
          adults: 1,
          children: 0,
          infants: 0,
        })
      );
    }
  }, []);

  const onChangeOriginField = (e: any) => {
    const { Location, airportName, IATA } = e.item;
    setFromField(() => ({ Location, airportName, IATA }));
    dispatch(setIsFromFieldDropdown(false));
    // InputRef.current?.value == 'test';
  };
  const onChangeDestinationField = (e: any) => {
    const { airportName, IATA, Location } = e.item;
    setToField(() => ({ Location, airportName, IATA }));
    dispatch(setIsToFieldDropdown(false));
  };

  const SwipeFromField2ToField = () => {
    if (fromField.IATA === "" || toField.IATA === "") {
      notifyError(`Fill all the fields `);
    } else {
      setFromField(toField);
      setToField(fromField);
      notifySuccess("successfully done");
    }
  };

  let TotalPassengers = adult + children + infant;
  const CheckNoOfTraveller = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const limitOfPassengers = 9;
    if (TotalPassengers > limitOfPassengers) {
      notifyError(`Max number of traveller is 9`);
      return (TotalPassengers = 1);
    } else {
      setIsTravellersClass(false);
      return TotalPassengers;
    }
  };

  const departureInput = (e: any) => {
    setDepartureDate(dayjs(e));
    dispatch(setIsDepartureCalender(false));
  };

  const onFormChangeHandler = (e: any) => {
    setFromField({ ...fromField, IATA: e.target.value });
  };

  const returnInput = (e: any) => {
    setReturnDate(dayjs(e));
    dispatch(setIsReturnCalender(false));
  };

  const lastCalenderComponentCloseFunction = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    if (state.isReturnCalender === true) {
      setIsReturnCalender(false);
      dispatch(setIsDepartureCalender(!state.isDepartureCalender));
      dispatch(setIsToFieldDropdown(false));
      dispatch(setIsFromFieldDropdown(false));
      dispatch(setIsCabinClassOptionsVisible(false));
      dispatch(TravelersPopupVisible(false));
      dispatch(setIsFromFieldDropdown(false));
    }
    dispatch(setIsDepartureCalender(!state.isDepartureCalender));
    dispatch(setIsToFieldDropdown(false));
    dispatch(setIsFromFieldDropdown(false));
    dispatch(setIsCabinClassOptionsVisible(false));
    dispatch(TravelersPopupVisible(false));
  };

  const roundTripActive = (e: React.MouseEvent<HTMLDivElement>) => {
    if (tripType === "oneway") {
      setTripType("roundtrip");
    } else if (tripType === "roundtrip") {
      e.stopPropagation();
      if (state.isDepartureCalender === true) {
        dispatch(setIsCabinClassOptionsVisible(false));
        dispatch(TravelersPopupVisible(false));
        dispatch(setIsFromFieldDropdown(false));
        dispatch(setIsDepartureCalender(false));
        dispatch(setIsCabinClassOptionsVisible(false));
        dispatch(setIsToFieldDropdown(false));
        dispatch(setIsReturnCalender(!state.isReturnCalender));
      }
      dispatch(setIsDepartureCalender(false));
      dispatch(setIsCabinClassOptionsVisible(false));
      dispatch(TravelersPopupVisible(false));
      dispatch(setIsFromFieldDropdown(false));
      dispatch(setIsToFieldDropdown(false));
      dispatch(setIsReturnCalender(!state.isReturnCalender));
    }
  };

  const submit = () => {
    if (tripType === "oneway" && TotalPassengers <= 9) {
      let oneWaySearchAirLeg: SearchAirLegsI = {
        cabinClass: classes,
        departureDate: departureDate.format("YYYY-MM-DD").toString(),
        destination: toField.IATA,
        origin: fromField.IATA,
      };

      const flightSearchParams: SearchParamsI = {
        destination: toField.IATA,
        origin: fromField.IATA,
        departureDate: departureDate.toDate().toISOString(),
        returnDate: null,
        totalTravelers: TotalPassengers,
        adults: state.searchParams.adults,
        children: state.searchParams.children,
        infants: state.searchParams.infants,
        tripType: "oneway",
        cabinClass: state.searchParams.cabinClass,
      };

      // #Storing Data in local Storage
      localStorage.setItem(
        "current_params",
        JSON.stringify(flightSearchParams)
      );
      dispatch(setFlightSearchParams(flightSearchParams));

      let flightSearchParamsForAPI = {
        adults: state.searchParams.adults,
        airline: "",
        children: state.searchParams.children,
        infants: state.searchParams.infants,
        nonstop: 0,
        solutions: 0,
        searchAirLegs: [oneWaySearchAirLeg],
        key: "search",
      };
      if (
        oneWaySearchAirLeg.destination === "" ||
        oneWaySearchAirLeg.origin === ""
      ) {
        notifyError("Something Wrong");
      } else {
        // setSearch(updateSearch);

        // #Storing Data in local Storage
        localStorage.setItem(
          "Flight_Search_Params",
          JSON.stringify(flightSearchParamsForAPI)
        );
        dispatch(fetchFlights(flightSearchParamsForAPI));
        Navigation(`/search/${tripType}`);
      }
    } else if (tripType === "roundtrip" && TotalPassengers <= 9) {
      let roundTripSearchAirLeg: SearchAirLegsI = {
        cabinClass: state.searchParams.cabinClass,
        departureDate: departureDate.format("YYYY-MM-DD").toString(),
        destination: toField.IATA,
        origin: fromField.IATA,
      };

      let returnRoundTripSearchAirLeg: SearchAirLegsI = {
        cabinClass: state.searchParams.cabinClass,
        departureDate: returnDate.format("YYYY-MM-DD").toString(),
        destination: fromField.IATA,
        origin: toField.IATA,
      };

      // setFlight(updateFlight);
      const flightSearchParams: SearchParamsI = {
        destination: toField.IATA,
        origin: fromField.IATA,
        departureDate: departureDate.toDate().toISOString(),
        returnDate: returnDate.toDate().toISOString(),
        totalTravelers: TotalPassengers,
        adults: state.searchParams.adults,
        children: state.searchParams.children,
        infants: state.searchParams.infants,
        tripType: "roundtrip",
        cabinClass: state.searchParams.cabinClass,
      };

      // #Storing Data in local Storage
      localStorage.setItem(
        "current_params",
        JSON.stringify(flightSearchParams)
      );
      dispatch(setFlightSearchParams(flightSearchParams));

      let flightSearchParamsForAPI = {
        adults: state.searchParams.adults,
        airline: "",
        children: state.searchParams.children,
        infants: state.searchParams.infants,
        nonstop: 0,
        solutions: 0,
        searchAirLegs: [roundTripSearchAirLeg, returnRoundTripSearchAirLeg],
        key: "search",
      };
      if (
        roundTripSearchAirLeg.destination === "" ||
        roundTripSearchAirLeg.origin === ""
      ) {
        notifyError("Something Wrong");
      } else {
        // #Storing Data in local Storage
        localStorage.setItem(
          "Flight_Search_Params",
          JSON.stringify(flightSearchParamsForAPI)
        );
        dispatch(fetchFlights(flightSearchParamsForAPI));
        Navigation(`/search/${tripType}`);
      }
    } else {
      notifyError("Something occurred wrong");
    }
  };

  const travellerClass = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsTravellersClass(!isTravellersClass);
  };

  const CloseAll = () => {
    dispatch(setIsCabinClassOptionsVisible(false));
    dispatch(TravelersPopupVisible(false));
    dispatch(setIsFromFieldDropdown(false));
    dispatch(setIsToFieldDropdown(false));
    setIsTravellersClass(false);
    dispatch(setIsDepartureCalender(false));
    dispatch(setIsReturnCalender(false));
  };

  return (
    <div
      className={
        tripType === "multicity"
          ? "w-full h-full md:h-full bg-cover bg-no-repeat bg-center"
          : `w-full h-full md:h-[35rem] lg:h-[30rem] bg-cover bg-no-repeat bg-center`
      }
      style={{ backgroundImage: `url(${Banner})` }}
      onClick={CloseAll}
    >
      <HeroNav />
      <div className="w-full h-full flex justify-center">
        <div
          className={
            tripType === "multicity"
              ? "relative w-full md:w-full lg:w-auto bg-white h-fit md:h-fit lg:h-fit shadow-md md:rounded-xl flex justify-center items-start lg:items-center mt-[5rem] px-4 lg:py-10 lg:px-12 mb-8"
              : "relative w-full md:w-full lg:w-auto bg-white h-[32rem] md:h-[22rem] lg:h-[15rem] shadow-md md:rounded-xl flex justify-center items-start lg:items-center mt-[5rem] px-4 lg:px-12"
          }
        >
          {!show && <SearchPathComponent />}
          <div className="flex w-full lg:w-auto flex-col items-center mt-[2rem] md:mt-[2.5rem] lg:mt-0">
            <TripTypeComponent
              tripType={tripType}
              setTripType={(e: tripTypeT) => setTripType(e)}
            />
            <div className="flex flex-col w-full relative">
              <div className="w-full flex flex-col lg:flex-row rounded-xl relative my-4 lg:my-2">
                <CityFieldInputComponent
                  InputRef={InputRef}
                  onFormChangeHandler={onFormChangeHandler}
                  fromField={fromField}
                  handleChange={handleChange}
                  fromFieldDropdown={state.isFromFieldDropdown}
                  onChangeOriginField={onChangeOriginField}
                  toField={toField}
                  toFieldDropdown={state.isToFieldDropdown}
                  onChangeDestinationField={onChangeDestinationField}
                />
                <div className="flex flex-col md:flex-row">
                  <div className="flex w-full lg:w-auto ">
                    <div
                      className="w-full md:rounded-l-xl lg:rounded-none lg:w-[11rem] h-full border-[2px] cursor-pointer flex flex-col items-start pl-4 justify-center py-1 hover:bg-red-100 duration-200"
                      onClick={lastCalenderComponentCloseFunction}
                    >
                      <p className="mb-1 md:mb-3 text-xs md:text-sm flex items-center gap-1">
                        Departure
                        <FaAngleDown className="text-[#ec2027]" />
                      </p>
                      <h2 className="text-xl md:text-3xl font-bold flex gap-2 items-end">
                        {departureDate ? departureDate.date() : "26"}
                        <p className="text-lg md:text-2xl font-semibold capitalize">
                          {departureDate
                            ? monthsName(departureDate) +
                              " " +
                              departureDate.year().toString()
                            : "JUL'24"}
                        </p>
                      </h2>
                      <p className="text-sm md:text-base font-semibold">
                        {departureDate ? daysName(departureDate) : "Friday"}
                      </p>
                    </div>
                    {state.isDepartureCalender && (
                      <Calendar2
                        tripType={""}
                        getSelectedDate={(e: any) => departureInput(e)}
                        Title="Departure"
                      />
                      // <Calendar
                      //   classCss="left-[1rem] top-[63%] md:left-[1rem] md:top-[9rem] lg:left-[48%] lg:top-[2.5rem]"
                      //   field={(e: any) => departureInput(e)}
                      // />
                    )}
                    {tripType !== "multicity" && (
                      <div
                        className="w-full lg:w-[11rem] h-full border-[2px] cursor-pointer flex flex-col items-start pl-4 justify-center py-1 hover:bg-red-100 duration-200 rounded-r-xl"
                        onClick={roundTripActive}
                      >
                        <p className="mb-1 md:mb-3 text-xs md:text-sm flex items-center gap-1">
                          Return <FaAngleDown className="text-[#ec2027]" />
                        </p>
                        {tripType === "oneway" ? (
                          <p className="text-base md:text-lg font-semibold">
                            Tap to add a return date
                          </p>
                        ) : (
                          <>
                            <h2 className="text-xl md:text-3xl font-bold flex gap-2 items-end">
                              {returnDate ? returnDate.date() : "26"}
                              <p className="text-lg md:text-2xl font-semibold hover:bg-red-100 duration-200">
                                {returnDate
                                  ? monthsName(returnDate) +
                                    " " +
                                    returnDate.year().toString()
                                  : `JUL'24`}
                              </p>
                            </h2>
                            <p className="text-sm md:text-base font-semibold">
                              {returnDate ? daysName(returnDate) : "Friday"}
                            </p>
                          </>
                        )}
                      </div>
                    )}
                    {state.isReturnCalender && (
                      <Calendar2
                        tripType={""}
                        getSelectedDate={(e: any) => returnInput(e)}
                        Title="Arrival"
                      />
                      // <Calendar
                      //   classCss="top-[63%] left-[10%] md:left-[27%] md:top-[9rem] lg:left-[63%] lg:top-[2.5rem]"
                      //   field={(e: any) => returnInput(e)}
                      // />
                    )}
                  </div>
                  {/* <SelectNumberOfTravellersComponent
                    setIsTravellersClass={travellerClass}
                    TotalPassengers={TotalPassengers}
                    classes={classes}
                    isTravellersClass={isTravellersClass}
                    setAdult={(e: number) => setAdult(() => e)}
                    setChildren={(e: number) => setChildren(() => e)}
                    setInfant={(e: number) => setInfant(() => e)}
                    setClasses={(e: string) => setClasses(() => e)}
                    CheckNoOfTraveller={CheckNoOfTraveller}
                    adults={adult}
                    children={children}
                    infant={infant}
                  /> */}
                  <div
                    className="flex absolute shadow-md items-center justify-center rounded-full cursor-pointer bg-white p-1 border-[1px] border-black/10 top-[5rem] md:top-[2.5rem] left-[80%] md:left-[47%] lg:left-[15.5rem] rotate-[90deg] md:rotate-0"
                    onClick={SwipeFromField2ToField}
                  >
                    <MdCompareArrows className="text-[#ec2027] text-3xl" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SearchButton Func={submit} />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
