import React from "react";
import { AdBanner1 } from "../../utils/images";

const Banner1 = () => {
  return (
    <div
      className="w-full my-2 md:w-[80%] lg:w-[60%] h-[10rem] md:h-[8rem] border-[1px] rounded-md border-black/20 bg-center bg-cover bg-no-repeat shadow-md"
      style={{ backgroundImage: `url(${AdBanner1})` }}
    ></div>
  );
};

export default Banner1;
