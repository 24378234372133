import React, { ChangeEvent, useEffect, useState } from "react";

export interface Payment {
  cardNum: string;
  cardType: string;
  cardExpiredDate: string;
  cvv: string;
  cardHolderName: string;
}

interface PaymentInfoProp {
  getForm: (obj: Payment) => void;
}

const PaymentInfoform: React.FC<PaymentInfoProp> = ({ getForm }) => {
  const [paymentInfo, setPaymentInfo] = useState<Payment>({
    cardNum: "",
    cardType: "",
    cardExpiredDate: "",
    cvv: "",
    cardHolderName: "",
  });

  const onChangeInputField = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPaymentInfo({ ...paymentInfo, [name]: value });
  };

  useEffect(() => {
    if (
      paymentInfo.cardExpiredDate !== "" &&
      paymentInfo.cardNum !== "" &&
      paymentInfo.cardType !== "" &&
      paymentInfo.cvv !== "" &&
      paymentInfo.cardHolderName !== ""
    ) {
      getForm(paymentInfo);
    }
  }, [paymentInfo, getForm]);

  //   console.log(paymentInfo);

  return (
    <form action="" className=" px-4 py-5">
      <div className="flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-24">
        <div className="flex flex-col md:flex-row gap-2 md:gap-8 w-full items-start md:items-center">
          <label htmlFor="method" className="font-semibold">
            Payment Method<span className="text-red-600">*</span>
          </label>
          <select
            name="cardType"
            id="method"
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full md:w-[10rem]"
            required
            onChange={onChangeInputField}
            defaultValue={"select"}
          >
            <option value="select">Select</option>
            <option value="M">MasterCard</option>
            <option value="V">Visa</option>
            <option value="A">American Express</option>
            <option value="DS">Discover</option>
          </select>
        </div>
        <div className="flex flex-col md:flex-row gap-2 md:gap-8 w-full items-start md:items-center">
          <label htmlFor="cardNumber" className="font-semibold">
            Credit or Debit Card Number
            <span className="text-red-600">*</span>
          </label>
          <input
            name="cardNum"
            id="cardNumber"
            pattern="[0-9],{12}"
            minLength={12}
            maxLength={12}
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full"
            placeholder="Enter You Card Details"
            required
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full items-start md:items-center my-4 gap-4 md:gap-[6rem]">
        <div className="flex flex-col md:flex-row w-full gap-2 md:gap-5 items-start md:items-center">
          <label htmlFor="CaHolderName" className="font-semibold">
            Card Holder Name<span className="text-red-600">*</span>
          </label>
          <input
            name="cardHolderName"
            id="cardHolderName"
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full md:w-[10rem]"
            placeholder="Card Holder Name"
            required
          />
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center w-full gap-2 md:gap-8">
          <label htmlFor="cardExpiredDate" className="font-semibold">
            Expiration<span className="text-red-600">*</span>
          </label>
          {/* <div className="flex w-full gap-4 items-center">
            <select
              name="month"
              id="month"
              className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-[10rem]"
              required
              defaultValue={"select"}
            >
              <option value="select">Select</option>
              <option value="01">Jan</option>
              <option value="02">Feb</option>
              <option value="03">Mar</option>
              <option value="04">Apr</option>
              <option value="05">May</option>
              <option value="06">Jun</option>
              <option value="07">Jul</option>
              <option value="08">Aug</option>
              <option value="09">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </select>
            <select
              name="year"
              id="year"
              className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-[10rem]"
              required
              defaultValue={"select"}
            >
              <option value="select">Select</option>
              <option value="2024">2024</option>
              <option value="2023">2025</option>
              <option value="2022">2026</option>
              <option value="2021">2027</option>
              <option value="2020">2028</option>
              <option value="2019">2029</option>
              <option value="2018">2030</option>
              <option value="2017">2031</option>
              <option value="2016">2032</option>
              <option value="2015">2033</option>
              <option value="2014">2034</option>
              <option value="2013">2035</option>
              <option value="2012">2036</option>
              <option value="2011">2037</option>
              <option value="2010">2038</option>
              <option value="2009">2039</option>
              <option value="2008">2040</option>
            </select>
          </div> */}
          <input
            name="cardExpiredDate"
            id="cardExpiredDate"
            type="date"
            pattern="yyyy-mm-dd"
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full md:w-[10rem]"
          />
        </div>
      </div>
      <div className="flex w-full items-start md:items-center my-4">
        <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-start md:items-center w-full">
          <label htmlFor="CVNumber" className="font-semibold">
            Card Verification Number
            <span className="text-red-600">*</span>
          </label>
          <input
            name="cvv"
            id="CVNumber"
            pattern="[0-9],{4}"
            maxLength={4}
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full"
            placeholder="Enter Card Verification No"
            required
          />
        </div>
      </div>
    </form>
  );
};

export default PaymentInfoform;
