// import { FlightData } from "../dummydata/Data";

export const DataFilter = (Id: string, flights: any[], segments: any) => {
  let Data: any;
  if (flights) {
    Data = flights.filter((id: any) => id.flightId === Id);
  }

  let segmentId: string[] = [];
  Data.map((item: any) =>
    item.segmengtIds.map((id: any) => segmentId.push(id))
  );
  let segment: {
    segmentId: string;
    airline: string;
    flightNum: string;
    equipment: string;
    cabinClass: string;
    bookingCode: string;
    availabilityCount: number;
    departure: string;
    arrival: string;
    departureTerminal: string;
    arrivalTerminal: string;
    departureDate: number;
    arrivalDate: number;
    flightTime: number;
    stayTime: null;
    codeShare: string;
    opFltNo: null;
    opFltAirline: string;
    stopover: null;
    fareBasis: string;
    strDepartureDate: string;
    strDepartureTime: string;
    strArrivalDate: string;
    strArrivalTime: string;
  }[] = [];

  segmentId.map((id) =>
    segments
      .filter((Id: any) => Id.segmentId === id)
      .map((item: any) => segment.push(item))
  );

  return segment;
};
