import React from "react";

const WorkingButton = ({
  classes,
  type,
  onClick,
  disabled,
  children,
}: {
  classes: string;
  type?: "button" | "reset" | "submit";
  onClick?: (event: any) => void;
  disabled?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <button
      className={classes}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default WorkingButton;
