import React from "react";
import { NavLink } from "react-router-dom";
import { NavRoutes } from "../../dummydata/Data";

const SearchPathComponent = () => {
  return (
    <div className="flex w-full md:w-full lg:w-fit items-center shadow-md gap-3 md:gap-[4rem] bg-white justify-center rounded-lg absolute top-[-2rem] md:top-[-3rem] md:px-4 py-1">
      {NavRoutes.map((e, index) => (
        <NavLink
          key={index}
          to={e.route}
          className={({ isActive }) =>
            isActive
              ? "flex flex-col items-center bg-transparent w-[3rem] md:w-[4rem] justify-center text-[#ec2027] border-b-2 border-[#ec2027] gap-1 h-full"
              : "flex flex-col items-center bg-transparent w-[3rem] md:w-[4rem] justify-center text-black hover:text-[#ec2027] border-b-2 border-white hover:border-[#ec2027] gap-1 h-full"
          }
        >
          <img
            src={e.icon}
            alt="Holidays"
            className="h-[2rem] md:h-[3rem] w-[2rem] md:w-[3rem]"
          />
          <p className="font-bold text-[0.5rem] md:text-[0.8rem] w-[3rem] md:w-[4rem] text-center whitespace-nowrap text-ellipsis overflow-hidden">
            {e.title}
          </p>
        </NavLink>
      ))}
    </div>
  );
};

export default SearchPathComponent;
