import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { MdCompareArrows } from "react-icons/md";
import { notifyError, notifySuccess } from "../../utils/notifications";
import { InputState } from "../homeComponent/HeroSection.component";
import fieldCity from "../../csvjson.json";
import dayjs from "dayjs";
// import Calendar from "../Calender.component";
import { monthsName } from "../../utils/ExtraFunction";
// import FlightTripTypeComponent from "./FlightSearch/FlightTripType.component";
import FlightCityFieldInputComponent from "./FlightSearch/FlightCityFieldInput.component";
// import FlightSelectNumberOfTravellersComponet from "./FlightSearch/FlightSelectNumberOfTravellers.componet";
import { useSelector } from "react-redux";
import {
  fetchFlights,
  setFlightSearchParams,
  setIsCabinClassOptionsVisible,
  setIsDepartureCalender,
  setIsFromFieldDropdown,
  setIsReturnCalender,
  setIsToFieldDropdown,
  TravelersPopupVisible,
  updateFlightSearchParams,
} from "../../store/reducers/flightSearch.reducer";
import { useAppDispatch } from "../../store";
import {
  airPortTypeT,
  cabinClassT,
  FlightRequestSearchParamsI,
  SearchParamsI,
  tripTypeT,
} from "../../types/flightSearchReducer.types";
import { useNavigate } from "react-router-dom";
import TripTypeComponent from "../homeComponent/HeroSection/TripType.component";
import Calendar2 from "../Calender2.component";

const FlightSearchComponent: React.FC = () => {
  const Navigation = useNavigate();
  const state = useSelector((state: any) => state.flights);
  const dispatch = useAppDispatch();

  const [tripType, setTripType] = useState<tripTypeT>(
    state.searchParams.tripType
  );
  // const [fromFieldDropdown, setFromFieldDropdown] = useState<boolean>(false);
  // const [toFieldDropdown, setToFieldDropdown] = useState<boolean>(false);
  const [isTravellersClass, setIsTravellersClass] = useState<boolean>(false);

  const [fromField, setFromField] = useState<InputState>({
    Location: `${
      state.searchParams.origin
        ? fieldCity.filter((loc) => loc.IATA === state.searchParams.origin)[0]
            .Location
        : ""
    }`,
    airportName: `${
      state.searchParams.origin
        ? fieldCity.filter((loc) => loc.IATA === state.searchParams.origin)[0]
            .airportName
        : ""
    }`,
    IATA: `${
      state.searchParams.origin
        ? fieldCity.filter((loc) => loc.IATA === state.searchParams.origin)[0]
            .IATA
        : ""
    }`,
  });
  const [toField, setToField] = useState<InputState>({
    Location: `${
      state.searchParams.origin
        ? fieldCity.filter(
            (loc) => loc.IATA === state.searchParams.destination
          )[0].Location
        : ""
    }`,
    airportName: `${
      state.searchParams.origin
        ? fieldCity.filter(
            (loc) => loc.IATA === state.searchParams.destination
          )[0].airportName
        : ""
    }`,
    IATA: `${
      state.searchParams.origin
        ? fieldCity.filter(
            (loc) => loc.IATA === state.searchParams.destination
          )[0].IATA
        : ""
    }`,
  });
  const [classes, setClasses] = useState<cabinClassT>(
    state.searchParams.cabinClass
  );
  const [adult, setAdult] = useState<number>(state.searchParams.adults);
  const [children, setChildren] = useState<number>(state.searchParams.children);
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  const [infant, setInfant] = useState<number>(state.searchParams.infants);
  // const [isDepartureCalender, setIsDepartureCalender] =
  //   useState<boolean>(false);
  // const [isReturnCalender, setIsReturnCalender] = useState<boolean>(false);
  const [departureDate, setDepartureDate] = useState(
    dayjs(state.searchParams.departureDate)
  );
  const [returnDate, setReturnDate] = useState(
    state.searchParams.returnDate ? dayjs(state.searchParams.returnDate) : null
  );

  const handleChange = (
    destinationType: "from" | "to",
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    if (destinationType === "from") {
      dispatch(setIsFromFieldDropdown(!state.isFromFieldDropdown));
      dispatch(setIsDepartureCalender(false));
      dispatch(setIsCabinClassOptionsVisible(false));
      dispatch(setIsReturnCalender(false));
      dispatch(TravelersPopupVisible(false));
      dispatch(setIsToFieldDropdown(false));
    } else if (destinationType === "to") {
      dispatch(setIsToFieldDropdown(!state.isToFieldDropdown));
      dispatch(setIsReturnCalender(false));
      dispatch(setIsDepartureCalender(false));
      dispatch(setIsCabinClassOptionsVisible(false));
      dispatch(TravelersPopupVisible(false));
      dispatch(setIsFromFieldDropdown(false));
    }
  };

  // useEffect(() => {
  //   if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
  //     console.log("inSide useEffect: ", state.searchParams);
  //   }
  // }, []);

  // console.log("outSide useEffect: ", state.searchParams);

  const onChangeInputFromField = (e: any) => {
    const { Location, airportName, IATA } = e.item;
    setFromField(() => ({ Location, airportName, IATA }));
    dispatch(updateFlightSearchParams({ origin: IATA }));
    dispatch(setIsFromFieldDropdown(false));
    // InputRef.current?.value == 'test';
  };
  const onChangeInputToField = (e: any) => {
    const { airportName, IATA, Location } = e.item;
    setToField(() => ({ Location, airportName, IATA }));
    dispatch(updateFlightSearchParams({ destination: IATA }));
    dispatch(setIsToFieldDropdown(false));
  };

  const changeField = () => {
    if (fromField.IATA === "" || fromField.IATA === "") {
      notifyError(`Fill all the fields `);
    } else {
      setFromField(toField);
      setToField(fromField);
      notifySuccess("successfully done");
    }
  };

  const TotalPassengers = adult + children + infant;
  const CheckNoOfTraveller = () => {
    const limitOfPassengers = 9;
    if (TotalPassengers > limitOfPassengers) {
      notifyError(`Max number of traveller is 9`);
    } else {
      notifySuccess(`successful Change`);
      setIsTravellersClass(false);
      return TotalPassengers;
    }
  };

  const departureInput = (e: any) => {
    setDepartureDate(dayjs(e));
    dispatch(
      updateFlightSearchParams({
        departureDate: departureDate.toDate().toISOString(),
      })
    );
    dispatch(setIsDepartureCalender(false));
  };

  const returnInput = (e: any) => {
    setReturnDate(dayjs(e));
    dispatch(
      updateFlightSearchParams({
        returnDate: returnDate ? returnDate.toDate().toISOString() : null,
      })
    );
    dispatch(setIsReturnCalender(false));
  };

  const lastCalenderComponentCloseFunction = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    if (state.isReturnCalender === true) {
      setIsReturnCalender(false);
      dispatch(setIsDepartureCalender(!state.isDepartureCalender));
      dispatch(setIsToFieldDropdown(false));
      dispatch(setIsFromFieldDropdown(false));
      dispatch(setIsCabinClassOptionsVisible(false));
      dispatch(TravelersPopupVisible(false));
      dispatch(setIsFromFieldDropdown(false));
    }
    dispatch(setIsDepartureCalender(!state.isDepartureCalender));
    dispatch(setIsToFieldDropdown(false));
    dispatch(setIsFromFieldDropdown(false));
    dispatch(setIsCabinClassOptionsVisible(false));
    dispatch(TravelersPopupVisible(false));
  };

  const roundTripActive = (e: React.MouseEvent<HTMLDivElement>) => {
    if (tripType === "oneway") {
      setTripType("roundtrip");
    } else if (tripType === "roundtrip") {
      e.stopPropagation();
      if (state.isDepartureCalender === true) {
        dispatch(setIsCabinClassOptionsVisible(false));
        dispatch(TravelersPopupVisible(false));
        dispatch(setIsFromFieldDropdown(false));
        dispatch(setIsDepartureCalender(false));
        dispatch(setIsCabinClassOptionsVisible(false));
        dispatch(setIsToFieldDropdown(false));
        dispatch(setIsReturnCalender(!state.isReturnCalender));
      }
      dispatch(setIsDepartureCalender(false));
      dispatch(setIsCabinClassOptionsVisible(false));
      dispatch(TravelersPopupVisible(false));
      dispatch(setIsFromFieldDropdown(false));
      dispatch(setIsToFieldDropdown(false));
      dispatch(setIsReturnCalender(!state.isReturnCalender));
    }
  };

  const submit = () => {
    if (tripType === "oneway") {
      let updateFlight = {
        cabinClass: state.searchParams.cabinClass,
        departureDate: departureDate.format("YYYY-MM-DD").toString(),
        destination: toField.IATA,
        origin: fromField.IATA,
      };

      const updatedFlightSearchParamsForState: SearchParamsI = {
        destination: toField.IATA,
        origin: fromField.IATA,
        departureDate: departureDate.toDate().toISOString(),
        returnDate: null,
        totalTravelers: TotalPassengers,
        adults: state.searchParams.adults,
        children: state.searchParams.children,
        infants: state.searchParams.infants,
        tripType: "oneway",
        cabinClass: state.searchParams.cabinClass,
      };

      // #Storing Data in local Storage
      localStorage.setItem(
        "current_params",
        JSON.stringify(updatedFlightSearchParamsForState)
      );
      dispatch(updateFlightSearchParams(updatedFlightSearchParamsForState));

      let updatedFlightSearchParams = {
        adults: state.searchParams.adults,
        airline: "",
        children: state.searchParams.children,
        infants: state.searchParams.infants,
        nonstop: 0,
        solution: 0,
        searchAirLegs: [updateFlight],
        key: "search",
      };
      if (updateFlight.destination === "" || updateFlight.origin === "") {
        notifyError("Something Wrong");
      } else {
        // #Storing Data in local Storage
        localStorage.setItem(
          "Flight_Search_Params",
          JSON.stringify(updatedFlightSearchParams)
        );
        dispatch(fetchFlights(updatedFlightSearchParams));
        setButtonClicked(true);
        Navigation(`/search/${tripType}`);
      }
    } else if (tripType === "roundtrip") {
      let updateFlight = {
        cabinClass: state.searchParams.cabinClass,
        departureDate: departureDate.format("YYYY-MM-DD").toString(),
        destination: toField.IATA,
        origin: fromField.IATA,
      };

      let returnFlight = {
        cabinClass: state.searchParams.cabinClass,
        departureDate: returnDate
          ? returnDate.format("YYYY-MM-DD").toString()
          : "",
        destination: fromField.IATA,
        origin: toField.IATA,
      };

      const updatedFlightSearchParamsForState: SearchParamsI = {
        destination: toField.IATA,
        origin: fromField.IATA,
        departureDate: departureDate.toDate().toISOString(),
        returnDate: returnDate ? returnDate.toDate().toISOString() : null,
        totalTravelers: TotalPassengers,
        adults: state.searchParams.adults,
        children: state.searchParams.children,
        infants: state.searchParams.infants,
        tripType: "roundtrip",
        cabinClass: state.searchParams.cabinClass,
      };

      // #Storing Data in local Storage
      localStorage.setItem(
        "current_params",
        JSON.stringify(updatedFlightSearchParamsForState)
      );
      dispatch(updateFlightSearchParams(updatedFlightSearchParamsForState));

      let updatedFlightSearchParams: FlightRequestSearchParamsI = {
        adults: state.searchParams.adults,
        airline: "",
        children: state.searchParams.children,
        infants: state.searchParams.infants,
        nonstop: 0,
        solution: 0,
        searchAirLegs: [updateFlight, returnFlight],
        key: "search",
      };
      if (updateFlight.destination === "" || updateFlight.origin === "") {
        notifyError("Something Wrong");
      } else {
        // #Storing Data in local Storage
        localStorage.setItem(
          "Flight_Search_Params",
          JSON.stringify(updatedFlightSearchParams)
        );
        dispatch(fetchFlights(updatedFlightSearchParams));
        setButtonClicked(true);
        Navigation(`/search/${tripType}`);
      }
    }
  };

  const travellerClass = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsTravellersClass(!isTravellersClass);
  };

  const CloseAll = () => {
    dispatch(setIsCabinClassOptionsVisible(false));
    dispatch(TravelersPopupVisible(false));
    dispatch(setIsFromFieldDropdown(false));
    dispatch(setIsToFieldDropdown(false));
    setIsTravellersClass(false);
    dispatch(setIsDepartureCalender(false));
    dispatch(setIsReturnCalender(false));
  };

  return (
    <div
      className="flex w-full lg:w-auto flex-col items-center mt-[4rem] md:mt-[2.5rem] lg:mt-0 relative z-50"
      onClick={CloseAll}
    >
      <TripTypeComponent
        textColour="text-white"
        tripType={tripType}
        setTripType={(e: any) => setTripType(e)}
      />
      <div className="flex flex-col w-full">
        <div className="w-full flex flex-col lg:flex-row rounded-xl relative my-4 lg:my-2">
          <FlightCityFieldInputComponent
            handleChange={handleChange}
            fromField={fromField}
            fromFieldDropdown={state.isFromFieldDropdown}
            toField={toField}
            toFieldDropdown={state.isToFieldDropdown}
            onChangeInputFromField={onChangeInputFromField}
            onChangeInputToField={onChangeInputToField}
          />
          <div className="flex flex-col md:flex-row">
            <div className="flex w-full lg:w-auto">
              <div
                className="w-full md:rounded-l-xl lg:rounded-none lg:w-[11rem] h-full border-[2px] cursor-pointer flex flex-col items-start pl-4 justify-center py-1 hover:bg-red-100 bg-white duration-200"
                onClick={lastCalenderComponentCloseFunction}
              >
                <p className="mb-1 md:mb-1 text-xs md:text-sm flex items-center gap-1">
                  Departure
                  <FaAngleDown className="text-[#ec2027]" />
                </p>
                <h2 className="text-xl md:text-2xl font-bold flex gap-2 items-end">
                  {departureDate ? departureDate.date() : "26"}
                  <p className="text-lg md:text-xl font-semibold capitalize">
                    {departureDate
                      ? monthsName(departureDate) +
                        " " +
                        departureDate.year().toString()
                      : "JUL'24"}
                  </p>
                </h2>
                {/* <p className='text-sm md:text-base font-semibold'>{departureDate ? Days(departureDate) : 'Friday'}</p> */}
              </div>
              {state.isDepartureCalender && (
                // <Calendar
                //   classCss="left-[1rem] top-[63%] md:left-[1rem] md:top-[6rem] lg:left-[48%] lg:top-[2.5rem]"
                //   field={(e: any) => departureInput(e)}
                // />
                <Calendar2
                  tripType={""}
                  getSelectedDate={(e: any) => departureInput(e)}
                  Title={"Departure"}
                />
              )}
              {tripType !== "multicity" && (
                <div
                  className="w-full lg:w-[11rem] h-full border-[2px] cursor-pointer flex flex-col items-start pl-4 justify-center py-1 hover:bg-red-100 bg-white duration-200 md:rounded-r-xl"
                  onClick={roundTripActive}
                >
                  <p className="mb-1 md:mb-1 text-xs md:text-sm flex items-center gap-1">
                    Return <FaAngleDown className="text-[#ec2027]" />
                  </p>
                  {tripType === "oneway" ? (
                    <p className="text-lg md:text-lg font-semibold">
                      return date
                    </p>
                  ) : (
                    <>
                      <h2 className="text-xl md:text-2xl font-bold flex gap-2 items-end">
                        {returnDate ? returnDate.date() : "26"}
                        <p className="text-lg md:text-xl font-semibold hover:bg-red-100 duration-200">
                          {returnDate
                            ? monthsName(returnDate) +
                              " " +
                              returnDate.year().toString()
                            : `JUL'24`}
                        </p>
                      </h2>
                      {/* <p className='text-sm md:text-base font-semibold'>{returnDate ? Days(returnDate) : 'Friday'}</p> */}
                    </>
                  )}
                </div>
              )}
              {state.isReturnCalender && (
                // <Calendar
                //   classCss="top-[63%] left-[20%] md:left-[27%] md:top-[6rem] lg:left-[63%] lg:top-[2.5rem]"
                //   field={(e: any) => returnInput(e)}
                // />
                <Calendar2
                  tripType={""}
                  getSelectedDate={(e: any) => returnInput(e)}
                  Title={"Arrival"}
                />
              )}
            </div>
            {/* <FlightSelectNumberOfTravellersComponet
              setIsTravellersClass={travellerClass}
              TotalPassengers={TotalPassengers}
              adult={adult}
              children={children}
              infant={infant}
              classes={classes}
              isTravellersClass={isTravellersClass}
              setAdult={(e: number) => setAdult(e)}
              setChildren={(e: number) => setChildren(e)}
              setInfant={(e: number) => setInfant(e)}
              setClasses={(e: cabinClassT) => setClasses(e)}
              CheckNoOfTraveller={CheckNoOfTraveller}
            /> */}
            <div
              className="flex absolute shadow-md items-center justify-center rounded-full cursor-pointer bg-white p-1 border-[1px] border-black/10 top-[2.5rem] md:top-[1.5rem] left-[80%] md:left-[47%] lg:left-[15.5rem] rotate-[90deg] md:rotate-0"
              onClick={changeField}
            >
              <MdCompareArrows className="text-[#ec2027] text-3xl" />
            </div>
          </div>
        </div>
      </div>
      <button
        className="rounded-full shadow-md w-[12rem] h-[2rem] flex items-center justify-center bg-white text-[#ec2027] font-bold py-2"
        onClick={submit}
      >
        Search
      </button>
    </div>
  );
};

export default FlightSearchComponent;
