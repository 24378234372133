import React from "react";
import CitySearch from "../../inputs/CitySearch.component";

interface FlightCityFieldInputComponentProp {
  handleChange: (
    e: "from" | "to",
    bubble: React.MouseEvent<HTMLDivElement>
  ) => void;
  fromField: any;
  fromFieldDropdown: boolean;
  toField: any;
  onChangeInputFromField: any;
  toFieldDropdown: boolean;
  onChangeInputToField: any;
}

const FlightCityFieldInputComponent: React.FC<
  FlightCityFieldInputComponentProp
> = ({
  handleChange,
  fromField,
  fromFieldDropdown,
  toField,
  toFieldDropdown,
  onChangeInputFromField,
  onChangeInputToField,
}) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div
        className="w-full lg:w-[17rem] h-full md:rounded-l-xl border-[2px] cursor-pointer flex flex-col items-start pl-4 justify-center py-1 hover:bg-red-100 bg-white duration-200"
        onClick={(bubble) => handleChange("from", bubble)}
      >
        <p className="mb-1 text-sm">From</p>
        <h2 className="text-xl font-bold w-[12rem] whitespace-nowrap text-ellipsis overflow-hidden">
          {fromField.Location ? fromField.Location : "Select"}
        </h2>
        {/* <p className='text-sm md:text-base font-semibold w-[12rem] whitespace-nowrap text-ellipsis overflow-hidden'>{field.airportName ? field.cityCode + ', ' + field.airportName : 'select'}</p> */}
      </div>
      {fromFieldDropdown && (
        <CitySearch classCss="left-[1rem]" fields={onChangeInputFromField} />
      )}
      <div
        className="w-full md:rounded-r-xl lg:rounded-none lg:w-[17rem] h-full border-[2px] cursor-pointer flex flex-col items-start pl-4 md:pl-8 justify-center py-1 hover:bg-red-100 bg-white duration-200"
        onClick={(bubble) => handleChange("to", bubble)}
      >
        <p className="mb-1 text-xs md:text-sm">To</p>
        <h2 className="text-xl font-bold w-[12rem] whitespace-nowrap text-ellipsis overflow-hidden">
          {toField.Location ? toField.Location : "Select"}
        </h2>
        {/* <p className='text-sm md:text-base font-semibold w-[12rem] whitespace-nowrap text-ellipsis overflow-hidden gap-1'>{field1.airportName ? field1.cityCode + ', ' + field1.airportName : 'Select'}</p> */}
      </div>
      {toFieldDropdown && (
        <CitySearch
          classCss="left-[1rem] top-[37%] md:top-[20%] lg:top-[45%] md:left-[53%] lg:left-[18rem]"
          fields={onChangeInputToField}
        />
      )}
    </div>
  );
};

export default FlightCityFieldInputComponent;
