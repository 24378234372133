import React, { useEffect } from "react";
import HeroSection from "../components/homeComponent/HeroSection.component";
import Banner1 from "../components/Banner/Banner1.component";
import Container from "../components/Containers/Container.component";
import Carousal from "../components/carousal/Carousal.component";
import { continents, states, Why } from "../dummydata/Data";
import CircleImg from "../components/Cards/circleImg.card";
import Routes from "../components/homeComponent/Routes.component";
import Blog from "../components/Cards/Blog.card";
import Achieve from "../components/Cards/Achieve.card";
import ReviewCard from "../components/Cards/Review.card";
import FaqAccordion from "../components/Accordion/Faq.accordion";

const Homepage = () => {
  return (
    <>
      <HeroSection />
      <div className="w-full flex flex-col items-center my-2">
        <Banner1 />
        {/* <Container title="Welcome to" title2="incredible India">
          <Carousal classcSS="h-[10rem]" gap="gap-8">
            {states.map((e) => (
              <CircleImg title={e.title} src={e.src} key={e.title} />
            ))}
          </Carousal>
        </Container>
        <Container title2="Wonder of World" title="Explore">
          <div className="flex flex-wrap md:flex-nowrap justify-center items-center mb-4">
            {continents.map((e) => (
              <CircleImg title={e.title} src={e.src} key={e.title} />
            ))}
          </div>
        </Container>
        <Banner1 />
        <Container title="Top" title2="Flight Routes">
          <Routes />
        </Container>
        <Container title="Travel Blogs" title2="Blogs">
          <Carousal classcSS="h-[14rem]" gap="gap-8">
            <Blog />
            <Blog />
            <Blog />
            <Blog />
            <Blog />
            <Blog />
            <Blog />
            <Blog />
            <Blog />
          </Carousal>
        </Container>
        <Container title="Why" title2="Dreams Tour & Travel">
          <div className="w-full flex flex-wrap lg:flex-nowrap items-center justify-center gap-4">
            {Why.map((e, index) => (
              <Achieve
                title={e.title}
                src={e.icons}
                desc={e.desc}
                key={index}
              />
            ))}
          </div>
        </Container>
        <Container
          title="Customer"
          title2="Reviews"
          des="What do our dream travelers say about us"
        >
          <Carousal classcSS="h-[16rem]" gap="gap-[5rem]">
            <ReviewCard />
            <ReviewCard />
            <ReviewCard />
            <ReviewCard />
            <ReviewCard />
            <ReviewCard />
            <ReviewCard />
            <ReviewCard />
            <ReviewCard />
          </Carousal>
        </Container>
        <Container title="Memories" title2=" ">
          <Carousal classcSS="h-[14rem]" gap="gap-8">
            <Blog />
            <Blog />
            <Blog />
            <Blog />
            <Blog />
            <Blog />
            <Blog />
            <Blog />
            <Blog />
          </Carousal>
        </Container> */}
        <Container title="FAQ" title2=" " des="Frequently Ask Questions">
          <FaqAccordion />
        </Container>
      </div>
    </>
  );
};

export default Homepage;
