import { configureStore } from "@reduxjs/toolkit";
import flightsReducer from "./reducers/flightSearch.reducer";
import { useDispatch } from "react-redux";

const store = configureStore({
  reducer: {
    flights: flightsReducer,
  },
});

// Typed versions of dispatch and state
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
