import React from "react";
import {
  Apple,
  Certificate1,
  Certificate2,
  Certificate3,
  Certificate4,
  FooterBG,
  Google,
} from "../utils/images";
import { Link } from "react-router-dom";
import Input from "./inputs/input";
import { DTT_LOGO } from "../utils/icons";

const FooterComponent = () => {
  return (
    <div
      className="w-full flex flex-col items-center border-t-[8px] h-fit border-[#ec2027] bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${FooterBG})` }}
    >
      <div className="flex w-full justify-center bg-black/85 text-white py-4 h-full">
        <div className="w-full px-1 md:px-4 lg:px-0 lg:w-[70%] flex flex-col items-start justify-center mb-10 md:mb-10">
          <h2 className="font-bold text-sm md:text-lg">
            Make your travel easy with a wide range of products and services.
          </h2>
          <div className="w-full flex items-start mt-4 gap-5 justify-between">
            <div className="flex flex-col items-start gap-2 text-xs md:text-base">
              <Link to={"/"} className="font-bold">
                Flight
              </Link>
              <Link to={"/"} className="font-bold">
                Bus
              </Link>
              <Link to={"/"} className="font-bold">
                Flight Status
              </Link>
              <Link to={"/"} className="font-bold">
                Check PNR Status
              </Link>
              <Link to={"/"} className="font-bold">
                Corporate Travel
              </Link>
            </div>
            <div className="flex flex-col items-start gap-2 text-xs md:text-base">
              <Link to={"/"} className="font-bold">
                Hotels
              </Link>
              <Link to={"/"} className="font-bold">
                Cabs
              </Link>
              <Link to={"/"} className="font-bold">
                Airlines
              </Link>
              <Link to={"/"} className="font-bold">
                DTT PRO
              </Link>
              <Link to={"/"} className="font-bold">
                Blog
              </Link>
            </div>
            <div className="flex flex-col items-start gap-2 text-xs md:text-base">
              <Link to={"/"} className="font-bold">
                Trains
              </Link>
              <Link to={"/"} className="font-bold">
                Cruise
              </Link>
              <Link to={"/"} className="font-bold">
                Airport
              </Link>
              <Link to={"/"} className="font-bold">
                Activities
              </Link>
              <Link to={"/"} className="font-bold">
                Flight Check-in
              </Link>
            </div>
            <div className="flex flex-col items-start gap-2 text-xs md:text-base">
              <Link to={"/"} className="font-bold">
                Holiday
              </Link>
              <Link to={"/"} className="font-bold">
                Charter
              </Link>
              <Link to={"/"} className="font-bold">
                Travel Guide
              </Link>
              <Link to={"/"} className="font-bold">
                Travel update
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full relative flex flex-col items-center justify-center py-2 bg-[#ec2027]/80 text-white">
        <div className="absolute top-[-1.5rem] w-fit h-fit flex justify-center items-center gap-3">
          <Input
            type="email"
            value=""
            label="Enter Email Address"
            id="Email"
            onCharge={() => {}}
          />
          <button className="rounded-full bg-[#ec2027] flex items-center justify-center py-3 px-3 md:px-6 shadow-md">
            Subscribe
          </button>
        </div>
        <div className="w-4/5 flex flex-col md:flex-row items-center justify-between gap-3 md:gap-2 px-4 md:px-0 my-[4rem] md:my-[2.5rem]">
          <Link to={"/"}>
            <img src={DTT_LOGO} alt="Logo" className="h-[5rem] md:h-[6rem]" />
          </Link>
          <p className="md:w-[65%] lg:w-[50%] font-semibold text-sm text-center">
            From Dreams to Destinations: Let's Explore Together!
          </p>
          <div className="flex md:flex-col gap-2">
            <div
              className=" cursor-pointer w-[135px] h-[40px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${Google})` }}
            ></div>
            <div
              className=" cursor-pointer w-[135px] h-[40px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${Apple})` }}
            ></div>
          </div>
        </div>
        <div className="flex w-full flex-wrap md:flex-nowrap items-center justify-center gap-2 lg:gap-6">
          <img
            className="bg-cover bg-no-repeat bg-center w-[12rem] h-[6rem]"
            src={Certificate1}
            alt="ing"
          />
          <img
            className="bg-cover bg-no-repeat bg-center w-[12rem] h-[3.5rem]"
            src={Certificate2}
            alt="ing"
          />
          <img
            className="bg-cover bg-no-repeat bg-center w-[12rem] h-[5rem]"
            src={Certificate3}
            alt="ing"
          />
          <img
            className="bg-cover bg-no-repeat bg-center w-[12rem] h-[5rem]"
            src={Certificate4}
            alt="ing"
          />
        </div>
        <hr className="w-full h-[2px] bg-white" />
        <div className="my-2 text-base font-bold">
          Copyright &copy; 2024 Dreams Travel & Tour
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
