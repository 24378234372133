import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { Faq } from "../../dummydata/Data";

const FaqAccordion = () => {
  const [active, setActive] = useState(null);

  const toggle = (i: any) => {
    if (active === i) {
      return setActive(null);
    }
    setActive(i);
  };
  return (
    <div className="flex justify-center items-center w-full md:w-[80%]">
      <div className="w-full">
        {Faq.map((e, index) => (
          <div
            className="bg-gradient-to-r from-[#ec2027] to-[#c81212] mb-2 text-white px-4 py-2 rounded-2xl cursor-pointer"
            key={index}
            onClick={() => toggle(index)}
          >
            <div className="flex items-center justify-between">
              <h1 className="md:text-xl font-bold">
                {e.Q ? e.Q : "Question 1"}
              </h1>
              <div className="bg-[#ffffff] rounded-full flex justify-center items-center p-1 md:p-2 cursor-pointer">
                {active === index ? (
                  <FaMinus className="text-black text-base md:text-xl" />
                ) : (
                  <FaPlus className="text-black text-base md:text-xl" />
                )}
              </div>
            </div>
            <div
              className={
                active === index
                  ? "w-[90%] h-auto max-h-[999px] transition-all text-sm md:text-base duration-300 mt-2"
                  : "w-[90%] max-h-0 overflow-hidden transition-all text-sm md:text-base duration-300 mt-2"
              }
            >
              {e.A
                ? e.A
                : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse, cupiditate! Aliquid repudiandae, aspernatur totam nobis temporibus officia placeat explicabo tempora incidunt, fugiat mollitia ducimus voluptas ad nulla itaque id autem unde! Fuga suscipit pariatur rem est minus dolores doloremque harum quae molestiae nostrum minima, blanditiis eaque tempore? Odit modi dolorem provident eaque voluptates beatae tempora expedita doloribus eligendi libero."}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqAccordion;
