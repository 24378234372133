import React, { ChangeEvent, useEffect, useState } from "react";

export interface ContactInfo {
  billingPhone: string;
  mobile: string;
  email: string;
  retypeEmail: string;
  agree: string;
}

interface ContactProp {
  getForm: (obj: ContactInfo) => void;
}
const ContactInfoForm: React.FC<ContactProp> = ({ getForm }) => {
  const [contact, setContact] = useState<ContactInfo>({
    billingPhone: "",
    mobile: "",
    email: "",
    retypeEmail: "",
    agree: "",
  });

  const onChangeInputField = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  useEffect(() => {
    if (
      contact.billingPhone !== "" &&
      contact.email !== "" &&
      contact.mobile !== "" &&
      contact.retypeEmail !== "" &&
      contact.agree !== ""
    ) {
      getForm(contact);
    }
  }, [contact, getForm]);

  return (
    <form action="" className="px-4 py-5">
      <div className="flex flex-col md:flex-row items-start md:items-center my-4 gap-4 md:gap-20">
        <div className="flex flex-col gap-2 items-start w-full">
          <label htmlFor="billingPhone" className="font-semibold">
            Billing Phone<span className="text-red-600">*</span>
          </label>
          <input
            name="billingPhone"
            id="billingPhone"
            type="text"
            onChange={onChangeInputField}
            pattern="[0-9],{10}"
            minLength={10}
            maxLength={10}
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full"
            required
          />
        </div>
        <div className="flex flex-col gap-2 items-start w-full">
          <label htmlFor="mobile" className="font-semibold">
            Mobile Phone<span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            onChange={onChangeInputField}
            pattern="[0-9],{10}"
            minLength={10}
            maxLength={10}
            name="mobile"
            id="mobile"
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full"
            required
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full gap-4 md:gap-[10rem] items-start md:items-center my-4">
        <div className="flex flex-col items-start gap-2 w-full">
          <label htmlFor="email" className="font-semibold">
            Email<span className="text-red-600">*</span>
          </label>
          <input
            onChange={onChangeInputField}
            type="email"
            name="email"
            id="email"
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full"
            required
          />
        </div>
        <div className="flex flex-col items-start gap-2 w-full">
          <label htmlFor="retypeEmail" className="font-semibold">
            Retype Email<span className="text-red-600">*</span>
          </label>
          <input
            type="email"
            onChange={onChangeInputField}
            name="retypeEmail"
            id="retypeEmail"
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full"
            required
          />
        </div>
      </div>
      <div className="flex items-center gap-2 mt-6">
        <input
          name="agree"
          id="check"
          type="checkbox"
          onChange={onChangeInputField}
        />
        <label htmlFor="agree" className="text-xs md:text-base">
          Send me the latest travel deals, special offers, coupons and fare
          sales.
        </label>
      </div>
    </form>
  );
};

export default ContactInfoForm;
