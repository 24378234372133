import React from "react";
import { NO_DATA_FOUND } from "../utils/icons";

const NotFoundPage = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <img
        src={NO_DATA_FOUND}
        alt="NO_DATA_FOUND"
        className="w-full h-[35rem]"
      />
      <h1 className="font-bold text-4xl">Page not found</h1>
    </div>
  );
};

export default NotFoundPage;
