import { toast, ToastOptions } from "react-toastify";

const defaultOptions: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "colored",
  progress: undefined,
};

export const notifySuccess = (message: string, options: ToastOptions = {}) => {
  toast.success(message, { ...defaultOptions, ...options });
};

export const notifyError = (message: string, options: ToastOptions = {}) => {
  toast.error(message, { ...defaultOptions, ...options });
};

export const notifyInfo = (message: string, options: ToastOptions = {}) => {
  toast.info(message, { ...defaultOptions, ...options });
};

export const notifyWarning = (message: string, options: ToastOptions = {}) => {
  toast.warning(message, { ...defaultOptions, ...options });
};
