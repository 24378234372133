import React from "react";
import { ticketLoading } from "../utils/icons";

interface ILoadingComponent {
  classCSS?: string;
}
const LoadingComponent: React.FC<ILoadingComponent> = ({ classCSS }) => {
  return (
    <div
      className={`${
        classCSS && classCSS
      } w-full h-full flex items-center justify-center`}
    >
      <img src={ticketLoading} alt="" className="w-[25rem] h-[25rem]" />
    </div>
  );
};

export default LoadingComponent;
