import React, { ChangeEvent, useEffect, useState } from "react";
import { PassengerI, psgTypeT } from "../../types/flightSearchReducer.types";

interface UserFormProp {
  getForm: (obj: PassengerI) => void;
  PsgType: psgTypeT;
  adultsLenght?: any;
  passengerId: number;
}

export enum PSGTYPE {
  ADT = "ADT",
  CHD = "CHD",
  INF = "INF",
}

const Userform: React.FC<UserFormProp> = ({
  getForm,
  PsgType,
  adultsLenght,
  passengerId,
}) => {
  const [UserBasicForm, setUserBasicForm] = useState<PassengerI>({
    passengerIndex: passengerId + 1,
    psgType: PsgType,
    nationality: "",
    birthday: "",
    firstName: "",
    lastName: "",
    sex: "",
    associatedPassengerIndex: 0,
  });

  const onChangeInputField = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setUserBasicForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  useEffect(() => {
    const { birthday, firstName, lastName, sex } = UserBasicForm;
    if (birthday && firstName && lastName && sex) {
      getForm(UserBasicForm);
    }
  }, [getForm, UserBasicForm]);

  return (
    <>
      <form
        action=""
        className="px-4 py-3 mb-3 border-[1px] border-black/10 rounded-md"
      >
        <div className="flex flex-col md:flex-row w-full gap-4 lg:gap-[7rem] items-start md:items-center">
          <div className="flex flex-col w-full">
            <label htmlFor="firstName" className="font-semibold">
              First Name <span className="text-red-600">*</span>
            </label>
            <input
              name="firstName"
              id="firstName"
              onChange={onChangeInputField}
              className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full "
              placeholder="Your Name"
              required
            />
          </div>
          <div className="flex flex-col w-full">
            <label htmlFor="lastName" className="font-semibold">
              Last Name <span className="text-red-600">*</span>
            </label>
            <input
              name="lastName"
              id="lastName"
              onChange={onChangeInputField}
              className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full"
              placeholder="Your Last Name"
              required
            />
          </div>
          <div className="flex flex-col w-full">
            <label htmlFor="nationality" className="font-semibold">
              Nationality <span className="text-red-600">*</span>
            </label>
            <input
              name="nationality"
              id="nationality"
              onChange={onChangeInputField}
              className="px-2 py-1 outline-none bg-[#f3f3f3] w-full border-[1px] shadow-md rounded-md uppercase"
              placeholder="2 letter from country name"
              maxLength={2}
              required
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row w-full gap-4 md:gap-[9.5rem] items-start md:items-center my-4">
          <div className="flex flex-col w-full">
            <label htmlFor="birthday" className="font-semibold">
              Date of Birth <span className="text-red-600">*</span>
            </label>
            <input
              type="date"
              name="birthday"
              id="birthday"
              onChange={onChangeInputField}
              className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full md:w-[10rem]"
              pattern="yyyy-mm-dd"
              required
            />
          </div>

          {PsgType === "INF" && (
            <div className="flex flex-col w-full">
              <label
                htmlFor="associatedPassengerIndex"
                className="font-semibold"
              >
                associated Passenger <span className="text-red-600">*</span>
              </label>
              <select
                name="associatedPassengerIndex"
                id="Passenger"
                className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full md:w-[10rem]"
                required
                onChange={onChangeInputField}
                defaultValue={"select"}
              >
                <option value={"select"}>Select</option>
                {Array.from({ length: adultsLenght }).map((_, index) => (
                  <option value={index + 1} key={index}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="flex flex-col w-full">
            <label htmlFor="sex" className="font-semibold">
              Gender <span className="text-red-600">*</span>
            </label>
            <select
              name="sex"
              id="sex"
              className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full md:w-[10rem]"
              required
              onChange={onChangeInputField}
              defaultValue={"select"}
            >
              <option value="select">Select</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>
        </div>
        <p className="mt-8 font-semibold text-sm">
          Rules require all travelers to provide their full name, date of birth
          that match exactly with the Passport or Government issued photo ID.
        </p>
      </form>
    </>
  );
};

export default Userform;
