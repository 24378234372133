import Fuse from "fuse.js"
import Array from '../csvjson.json'

export const search = (str: string) => {
    const options = {
        includeScore: true,
        keys: ['IATA', 'airportName', 'Location']
    }
    const fuse = new Fuse(Array, options)

    const result = fuse.search(str)

    return result;

}