import React from "react";
import { FaMoneyCheckDollar } from "react-icons/fa6";

interface FlightDetailsPriceCardProp {
  flightDetails: any;
  ancillaryPrice?: any;
}

const FlightDetailsPriceCard: React.FC<FlightDetailsPriceCardProp> = ({
  flightDetails,
  ancillaryPrice,
}) => {
  return (
    <div className="lg:w-[20%] h-full">
      <div className="w-full h-fit shadow-md border-[1px] rounded-md">
        <div className="w-full flex items-center bg-[#ec2027] h-[3rem] p-2 gap-3 rounded-t-md">
          <FaMoneyCheckDollar className="text-3xl text-white" />
          <h2 className="text-2xl text-white font-semibold">Price Summary</h2>
        </div>
        <div className="border-b-[1px] flex w-full justify-between items-center p-2">
          <p className="font-semibold">
            Adult x {flightDetails && flightDetails.solution.adults}
          </p>
          <p className="font-semibold">
            {flightDetails &&
              `$${
                flightDetails.solution.adtFare * flightDetails.solution.adults
              }`}
          </p>
        </div>
        {flightDetails && flightDetails.solution.children > 0 && (
          <div className="border-b-[1px] flex w-full justify-between items-center p-2">
            <p className="font-semibold">
              Children x {flightDetails.solution.children}
            </p>
            <p className="font-semibold">{`$${
              flightDetails.solution.chdFare * flightDetails.solution.children
            }`}</p>
          </div>
        )}
        {flightDetails && flightDetails.solution.infants > 0 && (
          <div className="border-b-[1px] flex w-full justify-between items-center p-2">
            <p className="font-semibold">
              Infants x {flightDetails.solution.infants}
            </p>
            <p className="font-semibold">{`$${
              flightDetails.solution.infFare * flightDetails.solution.infants
            }`}</p>
          </div>
        )}
        <div className="border-b-[1px] flex w-full justify-between items-center p-2">
          <p className="font-semibold">Total Taxes & Fares</p>
          <p className="font-semibold">{`$${
            flightDetails
              ? (
                  flightDetails.solution.adtTax +
                  flightDetails.solution.chdTax +
                  flightDetails.solution.merchantFee +
                  flightDetails.solution.platformServiceFee +
                  flightDetails.solution.tktFee +
                  (flightDetails.solution.infTax
                    ? flightDetails.solution.infTax
                    : 0) +
                  (ancillaryPrice ? ancillaryPrice : 0)
                ).toFixed(2)
              : 0
          }`}</p>
        </div>
        <div className="border-b-[1px] flex w-full justify-between items-center p-2">
          <p className="font-bold text-[#ec2027] text-lg">Grand Total</p>
          <p className="font-bold text-lg">{`$${
            flightDetails
              ? (
                  flightDetails.solution.adtTax *
                    flightDetails.solution.adults +
                  flightDetails.solution.chdTax *
                    flightDetails.solution.children +
                  flightDetails.solution.chdFare *
                    flightDetails.solution.children +
                  flightDetails.solution.adtFare *
                    flightDetails.solution.adults +
                  (ancillaryPrice ? ancillaryPrice : 0) +
                  (flightDetails.solution.infTax
                    ? flightDetails.solution.infTax *
                      flightDetails.solution.infants
                    : 0) +
                  (flightDetails.solution.infFare
                    ? flightDetails.solution.infFare *
                      flightDetails.solution.infants
                    : 0)
                ).toFixed(2)
              : 0
          }`}</p>
        </div>
      </div>
    </div>
  );
};

export default FlightDetailsPriceCard;
