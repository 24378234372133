import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postAPI } from "../../api/post.api";
import {
  FlightAncillaryBookingParamsI,
  FlightAncillaryPricingParamsI,
  FlightBookingRequestParamsI,
  FlightOrderDetailsRequestParamsI,
  FlightPrecisePricingParamsI,
  FlightRequestSearchParamsI,
  FlightsStateI,
  FlightTicketingRequestParamsI,
} from "../../types/flightSearchReducer.types";

const item = localStorage.getItem("current_params");

const parseItem = item && JSON.parse(item);

const initialState: FlightsStateI = {
  searchParams: {
    destination: parseItem ? parseItem.destination : "",
    origin: parseItem ? parseItem.origin : "",
    departureDate: parseItem ? parseItem.departureDate : null,
    returnDate: parseItem ? parseItem.returnDate : null,
    totalTravelers: parseItem ? parseItem.totalTravelers : 0,
    adults: 1,
    children: 0,
    infants: 0,
    tripType: parseItem ? parseItem.tripType : "oneway",
    cabinClass: parseItem ? parseItem.cabinClass : "Economy",
  },
  passengersDetails: [],
  flights: {},
  flightPrecisePricingData: {},
  flightAncillaryDetails: [],
  ancillaryPricingData: {},
  ancillaryBookingData: {},
  bookedFlightTicketData: {},
  flightOrderDetailsData: {},
  flightBookingData: {},
  filteredFlightList: [],
  ancillaryPricingFetchStatus: "idle",
  ancillaryBookingFetchStatus: "idle",
  flightBookingDataFetchStatus: "idle",
  flightTicketFetchStatus: "idle",
  flightOrderDetailsFetchStatus: "idle",
  flightListSearchStatus: "idle",
  flightPrecisePricingSearchStatus: "idle",
  error: null,
  isTravelersPopupVisible: false,
  isCabinClassOptionsVisible: false,
  isFromFieldDropdown: false,
  isToFieldDropdown: false,
  isDepartureCalender: false,
  isReturnCalender: false,
};

/**
 * Asynchronous thunk function to fetch flights based on the provided search parameters.
 *
 * @param searchParams - The parameters used to search for flights.
 * @param rejectWithValue - The callback function to handle rejection with a specific value.
 * @returns A promise that resolves to the fetched flight data or rejects with an error message.
 */
export const fetchFlights = createAsyncThunk(
  "flights/fetchFlights",
  async (
    flightSearchParams: FlightRequestSearchParamsI,
    { rejectWithValue }
  ) => {
    try {
      const data = await postAPI(
        process.env.REACT_APP_FlightSearchApiRoute as string,
        flightSearchParams,
        "search",
        "Failed to fetch flights"
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message as string);
    }
  }
);

/**
 * Asynchronous thunk function to fetch precise pricing for a flight.
 *
 * @param precisePricingFlightSearchParams - Parameters for fetching precise flight pricing
 * @param rejectWithValue - Redux toolkit function to handle rejected promise
 * @returns The fetched flight data or an error message
 */
export const fetchFlightPrecisePricing = createAsyncThunk(
  "flights/fetchFlightPrecisePricing",
  async (
    precisePricingFlightSearchParams: FlightPrecisePricingParamsI,
    { rejectWithValue }
  ) => {
    try {
      const data = await postAPI(
        process.env.REACT_APP_FlightPrecisePricingApiRoute as string,
        precisePricingFlightSearchParams,
        precisePricingFlightSearchParams.key,
        "Failed to fetch flight data"
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message as string);
    }
  }
);

/**
 * Asynchronous thunk function to fetch flight ancillary pricing data.
 *
 * @param flightAncillaryPricingSearchParams - Parameters for fetching flight ancillary pricing.
 * @param rejectWithValue - Redux toolkit callback function to handle rejected promise.
 * @returns The fetched flight ancillary pricing data or an error message.
 */
export const fetchFlightAncillaryPricing = createAsyncThunk(
  "flights/fetchFlightAncillaryPricing",
  async (
    flightAncillaryPricingSearchParams: FlightAncillaryPricingParamsI,
    { rejectWithValue }
  ) => {
    try {
      const data = await postAPI(
        process.env.REACT_APP_FlightAncillaryPricingApiRoute as string,
        flightAncillaryPricingSearchParams,
        flightAncillaryPricingSearchParams.key
          ? flightAncillaryPricingSearchParams.key
          : "",
        "Failed to fetch flight ancillary data"
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message as string);
    }
  }
);

/**
 * Asynchronous thunk function to fetch precise flight booking data.
 *
 * @param flightPreciseBookingRequestParams - Parameters for the flight booking request
 * @param rejectWithValue - Redux toolkit function to reject with a specific value
 * @returns The fetched flight booking data or an error message
 */
export const fetchFlightPreciseBooking = createAsyncThunk(
  "flights/fetchFlightPreciseBooking",
  async (
    flightPreciseBookingRequestParams: FlightBookingRequestParamsI,
    { rejectWithValue }
  ) => {
    try {
      const data = await postAPI(
        process.env.REACT_APP_FlightPreciseBookingApiRoute as string,
        flightPreciseBookingRequestParams,
        flightPreciseBookingRequestParams.key
          ? flightPreciseBookingRequestParams.key
          : "",
        "Failed to fetch flight booking data"
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message as string);
    }
  }
);

/**
 * Asynchronous thunk function to fetch flight ancillary booking data.
 *
 * @param flightAncillaryBookingRequestParams - Parameters for flight ancillary booking request
 * @param rejectWithValue - Redux toolkit function to reject with a value
 * @returns The fetched flight ancillary booking data or an error message
 */
export const fetchFlightAncillaryBooking = createAsyncThunk(
  "flights/fetchFlightAncillaryBooking",
  async (
    flightAncillaryBookingRequestParams: FlightAncillaryBookingParamsI,
    { rejectWithValue }
  ) => {
    try {
      const data = await postAPI(
        process.env.REACT_APP_FlightAncillaryBookingApiRoute as string,
        flightAncillaryBookingRequestParams,
        flightAncillaryBookingRequestParams.key
          ? flightAncillaryBookingRequestParams.key
          : "",
        "Failed to fetch flight Ancillary booking data"
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message as string);
    }
  }
);

/**
 * Asynchronous thunk function to fetch flight ticket data.
 *
 * @param flightTicketRequestParams - Parameters for the flight ticket request
 * @param rejectWithValue - Redux toolkit function to reject with a specific value
 * @returns A promise that resolves with the fetched flight ticket data or rejects with an error message
 */
export const fetchFlightTicket = createAsyncThunk(
  "flights/fetchFlightTicket",
  async (
    flightTicketRequestParams: FlightTicketingRequestParamsI,
    { rejectWithValue }
  ) => {
    try {
      const data = await postAPI(
        process.env.REACT_APP_FlightTicketingApiRoute as string,
        flightTicketRequestParams,
        flightTicketRequestParams.key ? flightTicketRequestParams.key : "",
        "Failed to fetch flight ticket data"
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message as string);
    }
  }
);

/**
 * Asynchronous thunk function to fetch flight order details.
 *
 * @param flightOrderDetailsRequestParams - Parameters for fetching flight order details.
 * @param rejectWithValue - Redux Toolkit function to reject the value if the request fails.
 * @returns The fetched flight order details data.
 */
export const fetchFlightOrderDetails = createAsyncThunk(
  "flights/fetchFlightOrderDetails",
  async (
    flightOrderDetailsRequestParams: FlightOrderDetailsRequestParamsI,
    { rejectWithValue }
  ) => {
    try {
      const data = await postAPI(
        process.env.REACT_APP_FlightOrderDetailsApiRoute as string,
        flightOrderDetailsRequestParams,
        flightOrderDetailsRequestParams.key
          ? flightOrderDetailsRequestParams.key
          : "",
        "Failed to fetch flight Order details data"
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message as string);
    }
  }
);

/**
 * Creates a flights slice for managing flight search state in the Redux store.
 * Includes reducers for setting and updating search parameters, and extra reducers for handling
 * pending, fulfilled, and rejected states of fetching flights asynchronously.
 */
const flightsSlice = createSlice({
  name: "flights",
  initialState,
  reducers: {
    setFlightSearchParams: (state, action) => {
      state.searchParams = action.payload;
    },
    updateFlightSearchParams: (state, action) => {
      state.searchParams = {
        ...state.searchParams,
        ...action.payload,
      };
    },
    addUser: (state, action) => {
      state.passengersDetails = action.payload;
    },
    addAncillary: (state, action) => {
      state.flightAncillaryDetails = action.payload;
    },
    updateFilteredFlightSearchList: (state, action) => {
      state.filteredFlightList = action.payload;
    },

    TravelersPopupVisible: (state, action) => {
      state.isTravelersPopupVisible = action.payload;
    },
    setIsCabinClassOptionsVisible: (state, action) => {
      state.isCabinClassOptionsVisible = action.payload;
    },

    setIsFromFieldDropdown: (state, action) => {
      state.isFromFieldDropdown = action.payload;
    },

    setIsToFieldDropdown: (state, action) => {
      state.isToFieldDropdown = action.payload;
    },

    setIsDepartureCalender: (state, action) => {
      state.isDepartureCalender = action.payload;
    },

    setIsReturnCalender: (state, action) => {
      state.isReturnCalender = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFlights.pending, (state) => {
        state.flightListSearchStatus = "loading";
      })
      .addCase(fetchFlights.fulfilled, (state, action) => {
        state.flightListSearchStatus = "succeeded";
        state.flights = action.payload;
      })
      .addCase(fetchFlights.rejected, (state, action) => {
        state.flightListSearchStatus = "failed";
        state.error =
          typeof action.payload === "string" ? action.payload : "Unknown error";
      })
      // handle status of fetchFlightPrecisePricing
      .addCase(fetchFlightPrecisePricing.pending, (state) => {
        state.flightPrecisePricingSearchStatus = "loading";
      })
      .addCase(fetchFlightPrecisePricing.fulfilled, (state, action) => {
        state.flightPrecisePricingSearchStatus = "succeeded";
        state.flightPrecisePricingData = action.payload;
      })
      .addCase(fetchFlightPrecisePricing.rejected, (state, action) => {
        state.flightPrecisePricingSearchStatus = "failed";
        state.error =
          typeof action.payload === "string" ? action.payload : "Unknown error";
      })
      // handle status of fetchFlightAncillaryPricing
      .addCase(fetchFlightAncillaryPricing.pending, (state) => {
        state.ancillaryPricingFetchStatus = "loading";
      })
      .addCase(fetchFlightAncillaryPricing.fulfilled, (state, action) => {
        state.ancillaryPricingFetchStatus = "succeeded";
        state.ancillaryPricingData = action.payload;
      })
      .addCase(fetchFlightAncillaryPricing.rejected, (state, action) => {
        state.ancillaryPricingFetchStatus = "failed";
        state.error =
          typeof action.payload === "string" ? action.payload : "Unknown error";
      })
      // handle status of fetchFlightPreciseBooking
      .addCase(fetchFlightPreciseBooking.pending, (state) => {
        state.flightBookingDataFetchStatus = "loading";
      })
      .addCase(fetchFlightPreciseBooking.fulfilled, (state, action) => {
        state.flightBookingDataFetchStatus = "succeeded";
        state.flightBookingData = action.payload;
      })
      .addCase(fetchFlightPreciseBooking.rejected, (state, action) => {
        state.flightBookingDataFetchStatus = "failed";
        state.error =
          typeof action.payload === "string" ? action.payload : "Unknown error";
      })
      // handle status of fetchFlightAncillaryBooking
      .addCase(fetchFlightAncillaryBooking.pending, (state) => {
        state.ancillaryBookingFetchStatus = "loading";
      })
      .addCase(fetchFlightAncillaryBooking.fulfilled, (state, action) => {
        state.ancillaryBookingFetchStatus = "succeeded";
        state.ancillaryBookingData = action.payload;
      })
      .addCase(fetchFlightAncillaryBooking.rejected, (state, action) => {
        state.ancillaryBookingFetchStatus = "failed";
        state.error =
          typeof action.payload === "string" ? action.payload : "Unknown error";
      })
      // handle status of fetchFlightTicket
      .addCase(fetchFlightTicket.pending, (state) => {
        state.flightTicketFetchStatus = "loading";
      })
      .addCase(fetchFlightTicket.fulfilled, (state, action) => {
        state.flightTicketFetchStatus = "succeeded";
        state.bookedFlightTicketData = action.payload;
      })
      .addCase(fetchFlightTicket.rejected, (state, action) => {
        state.flightTicketFetchStatus = "failed";
        state.error =
          typeof action.payload === "string" ? action.payload : "Unknown error";
      })
      // handle status of fetchFlightOrderDetails
      .addCase(fetchFlightOrderDetails.pending, (state) => {
        state.flightOrderDetailsFetchStatus = "loading";
      })
      .addCase(fetchFlightOrderDetails.fulfilled, (state, action) => {
        state.flightOrderDetailsFetchStatus = "succeeded";
        state.flightOrderDetailsData = action.payload;
      })
      .addCase(fetchFlightOrderDetails.rejected, (state, action) => {
        state.flightOrderDetailsFetchStatus = "failed";
        state.error =
          typeof action.payload === "string" ? action.payload : "Unknown error";
      });
  },
});

export const {
  setFlightSearchParams,
  updateFlightSearchParams,
  TravelersPopupVisible,
  setIsCabinClassOptionsVisible,
  setIsFromFieldDropdown,
  setIsDepartureCalender,
  setIsReturnCalender,
  setIsToFieldDropdown,
  addUser,
  addAncillary,
  updateFilteredFlightSearchList,
} = flightsSlice.actions;
export default flightsSlice.reducer;
