import { BackPack, Certificate, Support } from "../utils/icons";
import {
  Africa,
  Asia,
  Australia,
  Bihar,
  Bus,
  Cab,
  Delhi,
  Europe,
  Gujarat,
  Hariyana,
  Holiday,
  Hotel,
  Maharashtra,
  NorthAmerica,
  Odisha,
  Plane,
  Punjab,
  Rajasthan,
  TamilNadu,
  Train,
  Trust,
  Uttarakhand,
  UttarPradesh,
  WestBengal,
} from "../utils/images";

export const NavRoutes = [
  // {
  //   title: "Package",
  //   icon: Holiday,
  //   route: "/H",
  // },
  {
    title: "Flights",
    icon: Plane,
    route: "/",
  },
  // {
  //   title: "Hotels",
  //   icon: Hotel,
  //   route: "/HO",
  // },
  // {
  //   title: "Trains",
  //   icon: Train,
  //   route: "/trains",
  // },
  // {
  //   title: "Bus",
  //   icon: Bus,
  //   route: "/B",
  // },
  // {
  //   title: "Cab",
  //   icon: Cab,
  //   route: "/C",
  // },
];

export const city = [
  {
    name: "Delhi, India",
    cityCode: "DEL",
    airportName: "Indira Gandhi International Airport",
  },
  {
    name: "Mumbai, India",
    cityCode: "BOM",
    airportName: "Chhatrapati shivaji International Airport",
  },
  {
    name: "Bangkok, Thailand",
    cityCode: "BKK",
    airportName: "Bangkok",
  },
  {
    name: "Bengaluru, India",
    cityCode: "BLR",
    airportName: "Bengaluru International Airport",
  },
  {
    name: "Pune, India",
    cityCode: "PNQ",
    airportName: "Pune Airport",
  },
  {
    name: "Kolkata, India",
    cityCode: "CCU",
    airportName: "Netaji Subhash Chandra Bose International Airport",
  },
  {
    name: "Hyderabad, India",
    cityCode: "HYD",
    airportName: "Rajiv Gandhi International Airport",
  },
  {
    name: "Chennai, India",
    cityCode: "MAA",
    airportName: "Chennai International Airport",
  },
];

export const states = [
  {
    src: Uttarakhand,
    title: "Uttarakhand",
  },
  {
    src: Delhi,
    title: "Delhi",
  },
  {
    src: Punjab,
    title: "Punjab",
  },
  {
    src: Hariyana,
    title: "Hariyana",
  },
  {
    src: Bihar,
    title: "Bihar",
  },
  {
    src: Gujarat,
    title: "Gujarat",
  },
  {
    src: Maharashtra,
    title: "maharashtra",
  },
  {
    src: Rajasthan,
    title: "Rajasthan",
  },
  {
    src: TamilNadu,
    title: "Tamil-Nadu",
  },
  {
    src: Odisha,
    title: "Odisha",
  },
  {
    src: WestBengal,
    title: "West-Bengal",
  },
  {
    src: UttarPradesh,
    title: "Uttar-Pradesh",
  },
];

export const continents = [
  {
    src: Asia,
    title: "Aisa",
  },
  {
    src: Africa,
    title: "Africa",
  },
  {
    src: Australia,
    title: "Australia",
  },
  {
    src: Europe,
    title: "Europe",
  },
  {
    src: NorthAmerica,
    title: "NorthAmerica",
  },
];

export const Why = [
  {
    icons: Trust,
    title: "Trusted",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi fuga quisquam sit.",
  },
  {
    icons: BackPack,
    title: "Personalized tour",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi fuga quisquam sit.",
  },
  {
    icons: Certificate,
    title: "Certification",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi fuga quisquam sit.",
  },
  {
    icons: Support,
    title: "24*7 Customer Support",
    desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi fuga quisquam sit.",
  },
];
   

export const Faq = [
  {
    Q: "How do I connect with the DTT customer support team?",
    A: `You can connect with the DTT customer support team in several ways. Reach out via email at ${process.env.REACT_APP_DTT_EMAIL} or call us at ${process.env.REACT_APP_DTT_MOBILE}. Additionally, you can subscribe to our newsletter by dropping us an email, and our support team will be happy to assist you with any inquiries or concerns.`,
  },
  {
    Q: "What are the available payment options?",
    A: `You can easily pay for your flight tickets and other services using a variety of online payment methods. We accept debit cards, credit cards, net banking, and UPI, providing you with flexible options for a seamless transaction experience.`,
  },
  {
    Q: "Is it cheaper to book flights at the last minute?",
    A: "There is a popular belief that if you book flights in advance, your air tickets will be cheaper. However, contrary to the popular belief, booking airline tickets late is often cheaper. As per the research, flight tickets are generally cheaper if booked three weeks before departure than tickets booked 6 months ahead. But, this is not necessary. The price of flights fluctuates daily or even hourly in various cases. Many times, it changes due to the increasing demand for a particular route by passengers. So, sometimes flights get cheaper while traveling closer to the travel date and in some cases, it gets expensive.",
  },
  {
    Q: "Does the DTT do hotels too?",
    A: "Yes, we offer hotel booking services to ensure you have a perfect stay wherever you go. Our selection includes a variety of options, ranging from 3-star to 5-star hotels, allowing you to choose accommodations that suit your preferences and budget. Whether you're looking for luxury or comfort, we have something for everyone.",
  },
  {
    Q:"Does the DTT offer Holiday packages?",
    A:"Yes, we offer a variety of holiday packages tailored to different interests, including adventure, relaxation, and cultural experiences. We also provide personalized itineraries and often have seasonal promotions, making it easy to find the perfect getaway."
  }
];

export const FlightData = {
  success: true,
  data: {
    errorCode: "0",
    errorMsg: "ok",
    data: {
      searchKey: "BOMBKK20240730Y1000",
      shoppingKey: "2447b9fcebaf2c1d440a0b30a8f782c3",
      solutions: [
        {
          solutionKey: "ad9f7010cdc56047cb3d1f958bb99891",
          solutionId:
            "zWeXzKRBPMw/SGxIOThz9pIj/+fA/+TuTLZlpbRJmdssRcUZuBLiasu7B3WPO8EJzb/xN7uFAwrkzikH2Ie8YnEQXm8POUUYTn+mRcFU2vqKYnqrOBTitjsRqeOPMb3b7d/tyQJRbvCFs/2uQt8OKSdeb/V8wuZEeeYSjeLpiKKin5WKDdLVmhrqxp/BaoLbXDHIGvC9OaxxktW6GKOBlSKHCbtwU4snVj+2iGFq37G0btMPbrneZaw3pkGg4iSjcD2Y5jSeeo+SC7y7aKfryVoXwiwL9znux4zkuB3UmnCKhehWRCsD2OpPmm0WEb9WaxEqF134yAaZ/a1+/mAwLmw/xOE5xoAP7JRqzf3TCTM7cJLrUEw168aqi58FN9LFAq0+co0cVKuHXKWoJ95GbA==",
          fareType: "PRIVATE",
          currency: "USD",
          adtFare: 141.72,
          adtTax: 35.01,
          chdFare: 0,
          chdTax: 0,
          qCharge: 0,
          tktFee: 0,
          platformServiceFee: 0,
          comments: null,
          journeys: {
            journey_0: ["41a6244b68966a0283c1b96cbc51acdc"],
          },
          fareRule: null,
          rule: null,
          platingCarrier: "6E",
          prices: null,
          merchantFee: 0,
          adults: 1,
          children: 0,
          infants: 0,
          baggageMap: {
            ADT: [
              {
                segmentIndexList: [1, 2],
                baggageAmount: "1PC",
                baggageWeight: "20KG",
                carryOnAmount: "1PC",
                carryOnWeight: "7KG",
                carryOnSize: null,
              },
            ],
          },
          miniRuleMap: {
            ADT: [
              {
                segmentIndex: [1, 2],
                miniRules: [
                  {
                    penaltyType: 0,
                    isPermited: 0,
                    when: 0,
                    noShowTime: null,
                    noShowTimeUnit: null,
                    amount: null,
                    currencyCode: null,
                    percent: null,
                    baseType: null,
                  },
                  {
                    penaltyType: 1,
                    isPermited: 0,
                    when: 0,
                    noShowTime: null,
                    noShowTimeUnit: null,
                    amount: null,
                    currencyCode: null,
                    percent: null,
                    baseType: null,
                  },
                  {
                    penaltyType: 2,
                    isPermited: 0,
                    when: 0,
                    noShowTime: null,
                    noShowTimeUnit: null,
                    amount: null,
                    currencyCode: null,
                    percent: null,
                    baseType: null,
                  },
                  {
                    penaltyType: 3,
                    isPermited: 0,
                    when: 0,
                    noShowTime: null,
                    noShowTimeUnit: null,
                    amount: null,
                    currencyCode: null,
                    percent: null,
                    baseType: null,
                  },
                ],
              },
            ],
          },
          afterSaleRule: null,
        },
      ],
      flights: [
        {
          flightId: "41a6244b68966a0283c1b96cbc51acdc",
          journeyTime: 320,
          transferCount: 1,
          lastTktTime: null,
          segmengtIds: [
            "b9400533e991e458d9ab37ac60f798aa",
            "d1b1a8fe822e67195e04ee0d62d53582",
          ],
        },
      ],
      segments: [
        {
          segmentId: "b9400533e991e458d9ab37ac60f798aa",
          airline: "6E",
          flightNum: "5354",
          equipment: "32S",
          cabinClass: "ECONOMY",
          bookingCode: "V",
          availabilityCount: 9,
          departure: "BOM",
          arrival: "CCU",
          departureTerminal: "1",
          arrivalTerminal: "",
          departureDate: 1722322200000,
          arrivalDate: 1722331800000,
          flightTime: 160,
          stayTime: null,
          codeShare: "N",
          opFltNo: null,
          opFltAirline: "",
          stopover: null,
          fareBasis: "YOW",
          strDepartureDate: "2024-07-30",
          strDepartureTime: "14:50",
          strArrivalDate: "2024-07-30",
          strArrivalTime: "17:30",
        },
        {
          segmentId: "d1b1a8fe822e67195e04ee0d62d53582",
          airline: "6E",
          flightNum: "1057",
          equipment: "32S",
          cabinClass: "ECONOMY",
          bookingCode: "V",
          availabilityCount: 9,
          departure: "CCU",
          arrival: "BKK",
          departureTerminal: "",
          arrivalTerminal: "",
          departureDate: 1722346200000,
          arrivalDate: 1722361200000,
          flightTime: 160,
          stayTime: null,
          codeShare: "N",
          opFltNo: null,
          opFltAirline: "",
          stopover: null,
          fareBasis: "YOW",
          strDepartureDate: "2024-07-30",
          strDepartureTime: "21:30",
          strArrivalDate: "2024-07-31",
          strArrivalTime: "01:40",
        },
      ],
    },
  },
};
