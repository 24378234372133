import React, { ChangeEvent, useEffect, useState } from "react";

export interface AddressInfo {
  country: string;
  state: string;
  Address: string;
  city: string;
  zip: string;
}

interface AddressProp {
  getForm: (obj: AddressInfo) => void;
}

const CardBillAddressForm: React.FC<AddressProp> = ({ getForm }) => {
  const [address, setAddress] = useState<AddressInfo>({
    country: "",
    state: "",
    Address: "",
    city: "",
    zip: "",
  });

  const onChangeInputField = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  useEffect(() => {
    if (
      address.Address !== "" &&
      address.city !== "" &&
      address.country !== "" &&
      address.state !== "" &&
      address.zip !== ""
    ) {
      getForm(address);
    }
  }, [address, getForm]);

  return (
    <form action="" className="px-4 py-5">
      <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
        <div className="flex flex-col gap-2 w-full items-start">
          <label htmlFor="country" className="font-semibold">
            Country<span className="text-red-600">*</span>
          </label>
          <select
            name="country"
            id="country"
            defaultValue={"select"}
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full md:w-[15rem]"
            required
          >
            <option value="select">Select</option>
            <option value="IN">India</option>
            <option value="UK">United Kingdom</option>
            <option value="CA">Canada</option>
            <option value="AE">United Arab Emirates</option>
            <option value="AG">Antigua and Barbuda</option>
            <option value="AI">Anguilla</option>
            <option value="AM">Armenia</option>
            <option value="AN">Netherlands Antilles</option>
            <option value="AR">Argentina</option>
            <option value="AT">Austria</option>
            <option value="AU">Australia</option>
            <option value="AW">Aruba</option>
            <option value="AZ">Azerbaijan</option>
            <option value="BA">Bosnia Herzegovina</option>
            <option value="BB">Barbados</option>
            <option value="BE">Belgium</option>
            <option value="BG">Bulgaria</option>
            <option value="BH">Bahrain</option>
            <option value="BM">Bermuda</option>
            <option value="BN">Brunei Darussalam</option>
            <option value="BO">Bolivia</option>
            <option value="BR">Brazil</option>
            <option value="BS">Bahamas</option>
            <option value="BW">Botswana</option>
            <option value="BZ">Belize</option>
            <option value="CH">Switzerland</option>
            <option value="CL">Chile</option>
            <option value="CN">China</option>
            <option value="CR">Costa Rica</option>
            <option value="CY">Cyprus</option>
            <option value="CZ">Czech Republic</option>
            <option value="DE">Germany</option>
            <option value="DK">Denmark</option>
            <option value="DM">Dominica</option>
            <option value="DO">Dominican Republic</option>
            <option value="EC">Ecuador</option>
            <option value="EE">Estonia</option>
            <option value="EG">Egypt</option>
            <option value="ES">Spain</option>
            <option value="FI">Finland</option>
            <option value="FM">Micronesia</option>
            <option value="FR">France</option>
            <option value="GB">United Kingdom</option>
            <option value="GD">Grenada</option>
            <option value="GE">Georgia</option>
            <option value="GP">Guadeloupe</option>
            <option value="GR">Greece</option>
            <option value="GT">Guatemala</option>
            <option value="GU">Guam</option>
            <option value="GY">Guyana</option>
            <option value="HK">Hong Kong</option>
            <option value="HN">Honduras</option>
            <option value="HR">Croatia</option>
            <option value="HT">Haiti</option>
            <option value="HU">Hungary</option>
            <option value="ID">Indonesia</option>
            <option value="IE">Ireland</option>
            <option value="IL">Israel</option>
            <option value="IQ">Iraq</option>
            <option value="IS">Iceland</option>
            <option value="IT">Italy</option>
            <option value="JM">Jamaica</option>
            <option value="JO">Jordan</option>
            <option value="JP">Japan</option>
            <option value="KG">Kyrgyzstan</option>
            <option value="KH">Cambodia</option>
            <option value="KN">St. Christopher (St. Kitts) Nevis</option>
            <option value="KR">South Korea</option>
            <option value="KW">Kuwait</option>
            <option value="KY">Cayman Islands</option>
            <option value="KZ">Kazakstan</option>
            <option value="LA">Lao Peoples Democratic Republic</option>
            <option value="LB">Lebanon</option>
            <option value="LC">St. Lucia</option>
            <option value="LK">Sri Lanka</option>
            <option value="LT">Lithuania</option>
            <option value="LU">Luxembourg</option>
            <option value="LV">Latvia</option>
            <option value="MK">Republic of Macedonia</option>
            <option value="MN">Mongolia</option>
            <option value="MO">Macau</option>
            <option value="MQ">Martinique</option>
            <option value="MS">Montserrat</option>
            <option value="MT">Malta</option>
            <option value="MX">Mexico</option>
            <option value="MY">Malaysia</option>
            <option value="NA">Namibia</option>
            <option value="NI">Nicaragua</option>
            <option value="NL">Netherlands</option>
            <option value="NO">Norway</option>
            <option value="NZ">New Zealand</option>
            <option value="OM">Oman</option>
            <option value="PG">Papua New Guinea</option>
            <option value="PH">Philippines</option>
            <option value="PL">Poland</option>
            <option value="PM">St. Pierre and Miquelon</option>
            <option value="PS">Palestinian Territory, Occupied</option>
            <option value="PT">Portugal</option>
            <option value="PY">Paraguay</option>
            <option value="QA">Qatar</option>
            <option value="RO">Romania</option>
            <option value="RS">Serbia</option>
            <option value="RU">Russia</option>
            <option value="SA">Saudi Arabia</option>
            <option value="SE">Sweden</option>
            <option value="SG">Singapore</option>
            <option value="SI">Slovenia</option>
            <option value="SK">Slovakia</option>
            <option value="SM">San Marino</option>
            <option value="SR">Suriname</option>
            <option value="SV">El Salvador</option>
            <option value="SY">Syrian Arab Republic</option>
            <option value="TC">Turks and Caicos Islands</option>
            <option value="TH">Thailand</option>
            <option value="TR">Turkey</option>
            <option value="TT">Trinidad and Tobago</option>
            <option value="TW">Taiwan</option>
            <option value="UA">Ukraine</option>
            <option value="UY">Uruguay</option>
            <option value="VC">St. Vincent and The Grenadines</option>
            <option value="VE">Venezuela</option>
            <option value="VG">British Virgin Islands</option>
            <option value="VI">US Virgin Islands</option>
            <option value="VN">Vietnam</option>
            <option value="YE">Yemen</option>
            <option value="ZA">South Africa</option>
          </select>
        </div>
        <div className="flex flex-col items-start w-full gap-2">
          <label htmlFor="state" className="font-semibold">
            State<span className="text-red-600">*</span>
          </label>
          <input
            name="state"
            id="state"
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full md:w-[10rem]"
            placeholder="State Name"
            onChange={onChangeInputField}
            required
          />
        </div>
      </div>
      <div className="my-4">
        <div className="flex flex-col gap-2 items-start">
          <label htmlFor="Address" className="font-semibold">
            Address<span className="text-red-600">*</span>
          </label>
          <input
            name="Address"
            id="cardNumber"
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full"
            required
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-full items-start md:items-center gap-4 md:gap-[11rem] my-4">
        <div className="flex flex-col gap-2 w-full items-start">
          <label htmlFor="city" className="font-semibold">
            City<span className="text-red-600">*</span>
          </label>
          <input
            name="city"
            id="city"
            onChange={onChangeInputField}
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md w-full rounded-md md:w-[10rem]"
            placeholder="City Name"
            required
          />
        </div>
        <div className="flex flex-col w-full items-start gap-2">
          <label htmlFor="zip" className="font-semibold">
            Zip<span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            pattern="[0-9],{6}"
            minLength={6}
            maxLength={6}
            onChange={onChangeInputField}
            name="zip"
            id="zip"
            className="px-2 py-1 outline-none bg-[#f3f3f3] border-[1px] shadow-md rounded-md w-full md:w-[10rem]"
            placeholder="Zip Code"
            required
          />
        </div>
      </div>
    </form>
  );
};

export default CardBillAddressForm;
