import React from 'react'
import Navbarcomponent from '../components/Navbar.component'
import { Show } from '../utils/Show'
import { Outlet } from 'react-router-dom'

const ReviewLayout = () => {

    const show = Show()

    return (
        <>
            {
                !show && <Navbarcomponent />
            }
            <Outlet />
        </>
    )
}

export default ReviewLayout