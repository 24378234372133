import { useEffect, useState } from "react";

export const Show = ()=>{

    const [show, setShow] = useState(false);

  const ShowNav = ()=>{
    if(window.scrollY>100){
      setShow(true)
    }
    else{
      setShow(false)
    }
  }

  useEffect(()=>{
    window.addEventListener('scroll',ShowNav)
    return ()=> window.removeEventListener('scroll', ShowNav)
  },[]);

  return show;
}