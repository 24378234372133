import React, { useState } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import { BubbleEvent } from "../utils/ExtraFunction";

const Calendar2: React.FC<{
  tripType: string;
  getSelectedDate: Function;
  Title: string;
}> = ({ tripType, getSelectedDate, Title }) => {
  // Get the current date
  const currentDate = new Date();
  const [currentDay, setCurrentDay] = useState(currentDate.getDate());
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
  const [departureDate, setDepartureDate] = useState<Date>(new Date());
  const [arrivalDate, setArrivalDate] = useState<Date>(new Date());
  const [
    toggleBetweenDepartureAndArrival,
    setToggleBetweenDepartureAndArrival,
  ] = useState<"departure" | "arrival">("departure");
  const [limitOfCalendar, setLimitOfCalendar] = useState<number>(11);

  // Function to go to the next month
  const nextMonth = () => {
    if (limitOfCalendar > 1) {
      setCurrentMonth((prevMonth) => (prevMonth === 11 ? 0 : prevMonth + 1));
      setCurrentYear((prevYear) =>
        currentMonth === 11 ? prevYear + 1 : prevYear
      );
      setLimitOfCalendar((prev) => prev - 1);
    }
  };

  // Function to go to the previous month
  const prevMonth = () => {
    if (limitOfCalendar > 0 && limitOfCalendar < 11) {
      setCurrentMonth((prevMonth) => (prevMonth === 0 ? 11 : prevMonth - 1));
      setCurrentYear((prevYear) =>
        currentMonth === 0 ? prevYear - 1 : prevYear
      );
      setLimitOfCalendar((prev) => prev + 1);
    }
  };

  // Function to get the number of days in a month
  const getDaysInMonth = (month: number, year: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  // Function to get the first day of the month
  const getFirstDayOfMonth = (month: number, year: number) => {
    return new Date(year, month, 1).getDay();
  };

  const selectedDateHandler = (val: Date) => {
    // if (tripType === "one way" || tripType === "multicity") {
    setDepartureDate(val);
    // } else if (tripType === "round trip") {
    //   if (toggleBetweenDepartureAndArrival === "departure") {
    //     if (
    //       val > arrivalDate &&
    //       arrivalDate.toLocaleDateString() !==
    //         departureDate.toLocaleDateString() &&
    //       val >= new Date()
    //     ) {
    //       setDepartureDate(arrivalDate);
    //       setArrivalDate(val);
    //       setToggleBetweenDepartureAndArrival("arrival");
    //     } else {
    //       if (val >= new Date()) {
    //         setDepartureDate(val);
    //         setToggleBetweenDepartureAndArrival("arrival");
    //       }
    //     }
    //   } else if (toggleBetweenDepartureAndArrival === "arrival") {
    //     if (
    //       val < departureDate &&
    //       arrivalDate.toLocaleDateString() !==
    //         departureDate.toLocaleDateString() &&
    //       val >= new Date()
    //     ) {
    //       setArrivalDate(departureDate);
    //       setDepartureDate(val);
    //       setToggleBetweenDepartureAndArrival("departure");
    //     } else {
    //       if (val >= new Date()) {
    //         setArrivalDate(val);
    //         setToggleBetweenDepartureAndArrival("departure");
    //       }
    //     }
    //   }
    // }
  };

  // Array of weekdays
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Array of month names
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Generate the calendar grid
  const renderCalendar = (curMonth = currentMonth) => {
    const daysInMonth = getDaysInMonth(curMonth, currentYear);
    const firstDayOfMonth = getFirstDayOfMonth(curMonth, currentYear);
    const monthStart = new Date(currentYear, curMonth, 1);

    const calendarGrid = [];

    // Add empty cells for the days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarGrid.push(
        <div key={`empty-${i}`} className="cursor-default"></div>
      );
    }

    // Add cells for each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentYear, currentMonth, day);
      calendarGrid.push(
        <div
          key={`day-${day}`}
          className={`${
            currentDay === day &&
            new Date().getMonth() === curMonth &&
            new Date().getFullYear() === currentYear
              ? "bg-blue-300"
              : "cursor-pointer"
          }
        ${
          currentDay > day &&
          new Date().getMonth() === curMonth &&
          new Date().getFullYear() === currentYear
            ? "bg-slate-300 cursor-not-allowed hover:bg-slate-300 opacity-25 hover:border-slate-300"
            : ""
        } 
        ${
          departureDate.getDate() === day &&
          departureDate.getMonth() === curMonth &&
          departureDate.getFullYear() === currentYear
            ? "bg-blue-300 "
            : "cursor-pointer"
        }
        ${
          tripType === "round trip" &&
          arrivalDate.getDate() === day &&
          arrivalDate.getMonth() === curMonth &&
          arrivalDate.getFullYear() === currentYear
            ? "bg-blue-300"
            : "cursor-pointer"
        } 
        text-center p-1 border rounded-md hover:bg-blue-100 hover:border-blue-400 text-slate-600 text-xs font-semibold`}
          onClick={() =>
            !(
              currentDay > day &&
              new Date().getMonth() === curMonth &&
              new Date().getFullYear() === currentYear
            ) && selectedDateHandler(new Date(currentYear, curMonth, day))
          }
        >
          {day}
        </div>
      );
    }

    return calendarGrid;
  };

  return (
    <div
      onClick={BubbleEvent}
      className={`${"w-fit lg:w-[25vw]"} bg-white border rounded-xl shadow-[0_0px_10px_.1px_rgba(0,0,0,0.3)] p-4 h-fit z-40 absolute top-[13.5rem] md:top-[8rem] lg:top-[1rem] opacity-100`}
    >
      <div className="flex justify-between items-center mb-5">
        <GrPrevious
          onClick={prevMonth}
          className="cursor-pointer text-slate-600 border-2 bg-white  rounded-full p-2 w-10 h-10 shadow-[0_0px_10px_.1px_rgba(0,0,0,0.3)]"
        />
        <p className="text-slate-500 font-semibold">
          {Title} : {new Date(departureDate).toLocaleDateString()}
        </p>
        <GrNext
          onClick={nextMonth}
          className="cursor-pointer text-slate-600 border-2 bg-white  rounded-full p-2 w-10 h-10 shadow-[0_0px_10px_.1px_rgba(0,0,0,0.3)]"
        />
      </div>
      <div className={`${""}`}>
        <div>
          <h2 className="text-center">{`${months[currentMonth]} ${currentYear}`}</h2>
          <div className="grid grid-cols-7 gap-1 mt-5">
            {weekdays.map((weekday) => (
              <div key={weekday} className="text-center text-sm p-1">
                {weekday}
              </div>
            ))}
            {renderCalendar()}
          </div>
        </div>
      </div>
      <div className="text-end mt-5">
        <button
          type="button"
          className="border-2 rounded-full py-2 px-4 w-1/4 text-center bg-blue-200 border-blue-300"
          onClick={() => getSelectedDate(departureDate, arrivalDate)}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default Calendar2;
