import React, { FC } from "react";

interface InputProps {
  id: string;
  onCharge: any;
  value: string;
  label: string;
  type?: string;
}

const Input: FC<InputProps> = ({ id, onCharge, value, label, type }) => {
  return (
    <div className="relative">
      <input
        id={id}
        type={type || "text"}
        value={value}
        onChange={onCharge}
        className="
                block
                rounded-full
                px-6
                pt-6
                pb-1
                w-full
                text-md
                text-black
                bg-[#F0F3F4]
                appearance-none
                focus:outline-none
                focus:ring-0
                peer
            "
        placeholder=" "
      />
      <label
        htmlFor={id}
        className="
                absolute
                text-md
                text-black
                duration-150
                transform
                -translate-y-3
                scale-75
                top-4
                z-10
                origin-[0]
                left-6
                peer-placeholder-shown:scale-100
                peer-placeholder-shown:translate-y-0
                peer-focus:scale-75
                peer-focus:-translate-y-3
                "
      >
        {label}
      </label>
    </div>
  );
};

export default Input;
