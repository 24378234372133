import React from "react";

interface SearchButtonProp {
  Func: () => void;
  CSSClass?: string;
}

const SearchButton: React.FC<SearchButtonProp> = ({ Func, CSSClass }) => {
  return (
    <button
      className={` ${CSSClass} rounded-full absolute bottom-[1rem] md:bottom-[-1.5rem] shadow-md z-30 w-[12rem] h-[3rem] flex items-center justify-center bg-gradient-to-r from-[#ec2027] to-[#c81212] text-white font-bold py-2`}
      onClick={Func}
    >
      Search
    </button>
  );
};

export default SearchButton;
