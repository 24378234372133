/**
 * Sends a POST request to the specified API URL with the provided parameters.
 *
 * @param {string} apiURL - The URL to send the POST request to.
 * @param {any} params - The parameters to include in the request body.
 * @param {string} errorMessage - The error message to throw in case of an error.
 * @returns {Promise<any>} A Promise that resolves to the JSON response from the API.
 * @throws {Error} If an error occurs during the POST request.
 */
export const postAPI = async (
  apiURL: string,
  params: any,
  key: string,
  errorMessage: string
) => {
  try {
    delete params.key;
    const response = await fetch(apiURL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        [key]: params,
      }),
    });
    const responseJson = await response.json();
    if (!responseJson.data.data) {
      throw new Error("No data found");
    }
    return responseJson;
  } catch (error) {
    throw new Error(`${errorMessage}`);
  }
};
