import React from 'react'
// import { PaperPlane } from '../../utils/images'

interface Props {
  title?: string;
  title2?: string;
  children?: React.ReactNode;
  des?: string;
}

const Container: React.FC<Props> = ({ title, title2, children, des }) => {
  return (
    <div className='w-full flex flex-col items-center justify-center h-fit md:py-2 my-4'>
      <div className='mb-3 flex flex-col items-center'>
        <h2 className='flex items-center leading-4 gap-1'>
          <span className='px-3 bg-[#ec2027] text-white font-bold text-xl rounded-full'>{title ? title : 'Exclusive'}</span>
          <span className='text-black font-bold text-xl'>{title2 ? title2 : 'Offers'}</span>
          {/* <img src={PaperPlane} alt="" className='h-[7rem]' /> */}
        </h2>
        {des && <p className='font-bold text-sm text-center'>{des?des:`What do our dream travelers say about us`}</p>}
      </div>
      {
        children
      }
    </div>
  )
}

export default Container
