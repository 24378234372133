import FlightSearchComponent from "../components/searchComponet/FlightSearch.component";
import { Show } from "../utils/Show";
import TicketDetailAccordin from "../components/Accordion/TicketDetail.accordin";
import { Banner } from "../utils/images";
import FilterComponent from "../components/searchComponet/Filter.component";
import RoundTripAccordin from "../components/Accordion/RoundTrip.accordin";
import { useParams } from "react-router-dom";
import LoadingComponent from "../components/Loading.component";
import { useSelector } from "react-redux";
import NoDataFoundComponent from "../components/NoDataFound.component";
import { Cheap, Fast } from "../utils/icons";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../store";
import {
  fetchFlights,
  updateFlightSearchParams,
} from "../store/reducers/flightSearch.reducer";

const FlightSearchPage = () => {
  const dispatch = useAppDispatch();
  const state = useSelector((state: any) => state.flights);

  // #GET Data in local Storage
  const Flight_Search_Params = localStorage.getItem("Flight_Search_Params");

  const Params = Flight_Search_Params ? JSON.parse(Flight_Search_Params) : null;

  const Set_Search_Params = localStorage.getItem("current_params");

  const setParams = Set_Search_Params && JSON.parse(Set_Search_Params);

  useEffect(() => {
    if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
      dispatch(updateFlightSearchParams({ ...setParams }));
      dispatch(fetchFlights(Params));
    }
  }, []);

  const [flightSolutionsList, setFlightSolutionsList] = useState<
    Record<any, any>[]
  >([]);
  const [FlightSegmentsList, setFlightSegmentList] = useState<
    Record<any, any>[]
  >([]);

  const { tripType } = useParams();
  const showNav = Show();

  useEffect(() => {
    if (
      state.flightListSearchStatus === "succeeded" &&
      state.flights.data.data !== null
    ) {
      setFlightSolutionsList(() => [...state.flights.data.data.solutions]);
      setFlightSegmentList(() => [...state.flights.data.data.segments]);
    } else {
      setFlightSolutionsList([]);
      setFlightSegmentList([]);
    }
  }, [state.flightListSearchStatus]);

  return (
    <>
      <div
        className={
          !showNav
            ? " md:fixed top-[4rem] h-fit py-3 w-full md:flex items-center justify-center z-20 bg-cover bg-center bg-no-repeat"
            : "bg-cover bg-center bg-no-repeat md:fixed transform h-fit py-3 w-full flex items-center justify-center z-20"
        }
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <FlightSearchComponent />
      </div>
      <div className="flex items-start justify-center w-full gap-2 lg:gap-4 h-full min-h-screen bg-[#e5eef4]">
        {state.flightListSearchStatus === "loading" && (
          <LoadingComponent classCSS="md:mt-[25rem] lg:mt-[15rem]" />
        )}
        {state.flightListSearchStatus === "failed" && (
          <div className="w-full md:w-[90%] lg:w-[70%] h-fit p-2 mt-[1rem] md:mt-[25rem] lg:mt-[15rem]">
            <NoDataFoundComponent />
          </div>
        )}
        {state.flightListSearchStatus === "succeeded" && (
          <>
            {/* <div className="hidden md:block w-[25%] h-fit p-2 md:mt-[25rem] lg:mt-[15rem] rounded-sm overflow-auto">
              <FilterComponent />
            </div> */}
            <div className="w-full md:w-[90%] lg:w-[70%] h-fit p-2 mt-[1rem] md:mt-[25rem] lg:mt-[17rem]">
              {/* <div className="flex w-full h-[3rem] mb-4 items-center gap-2">
                <h2>Sort By:</h2>
                <div className="uppercase cursor-pointer p-1 border-[2px] shadow-md bg-white border-black/20 rounded-lg text-black flex items-center gap-2 font-semibold hover:bg-[#e74c3c] hover:text-white">
                  <img src={Cheap} alt="cheap" className=" h-[1.5rem]" />
                  Cheapest
                </div>
                <div className="uppercase cursor-pointer p-1 border-[2px] shadow-md bg-white border-black/20 rounded-lg text-black flex items-center gap-2 font-semibold hover:bg-[#e74c3c] hover:text-white">
                  <img src={Fast} alt="fastest" className="h-[1.5rem]" />
                  Fastest
                </div>
              </div> */}
              {tripType === "oneway" &&
                state.flightListSearchStatus === "succeeded" &&
                flightSolutionsList.map((item: any, index: any) => (
                  <TicketDetailAccordin
                    key={index}
                    solutions={item}
                    flights={state.flights.data.data.flights}
                    segments={FlightSegmentsList}
                  />
                ))}
              {tripType === "roundtrip" &&
                state.flightListSearchStatus === "succeeded" &&
                flightSolutionsList.map((item: any, index: any) => (
                  <RoundTripAccordin
                    key={index}
                    solution={item}
                    flights={state.flights.data.data.flights}
                    segments={FlightSegmentsList}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FlightSearchPage;
