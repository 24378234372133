import React from "react";
import { IoAirplaneSharp } from "react-icons/io5";
import { Indigo, NO_DATA } from "../../utils/images";
import Airport from "../../csvjson.json";
import { AirlineFilterFunction } from "../../utils/ExtraFunction";

interface StatusDetailsProp {
  journey: any;
  index: number;
  pnr: string;
}

const StatusDetailsComponent: React.FC<StatusDetailsProp> = ({
  journey,
  index,
  pnr,
}) => {
  return (
    <div className="bg-white w-full h-fit rounded-md shadow-md border-[1px] border-black/20 my-4">
      <div className="w-full h-[3rem] flex gap-x-[6rem] bg-[#ec2027] font-semibold text-white rounded-t-md px-4">
        <h2 className="w-1/4">Flight Details </h2>
        <h2 className="w-1/4">Departure</h2>
        <h2 className="w-1/4">Arrival</h2>
        <h2 className="w-1/4">Status</h2>
      </div>
      <div className="w-full h-[2.5rem] flex bg-black/35 text-white px-3">
        <h2 className="text-lg font-semibold">
          {index === 1 ? "Return Flight" : "Departure Flight"}
        </h2>
      </div>
      <div className="flex w-full h-[10rem] gap-[6rem] lg:gap-[4rem] item-center px-4 py-2">
        <div className="flex h-full items-start gap-1 w-[20%]">
          <div
            className="bg-cover bg-no-repeat bg-center w-[2.5rem] h-[2.5rem]"
            style={{
              backgroundImage: `url(${
                journey.segments[0].airline
                  ? AirlineFilterFunction(journey.segments[0].airline)
                      .image_link
                  : NO_DATA
              })`,
            }}
          ></div>
          <div className="flex flex-col gap-2">
            <p className="text-base lg:text-base font-bold">{`${
              journey.segments[0].airline &&
              AirlineFilterFunction(journey.segments[0].airline).Company_Name
            } ${
              journey.segments[0].airline ===
              journey.segments[journey.segments.length - 1].airline
                ? `${journey.segments[0].airline}`
                : `${journey.segments[0].airline}, ${
                    journey.segments[journey.segments.length - 1].airline
                  }`
            } ${
              journey.segments[0].flightNum ===
              journey.segments[journey.segments.length - 1].flightNum
                ? `${journey.segments[0].flightNum}`
                : `${journey.segments[0].flightNum}, ${
                    journey.segments[journey.segments.length - 1].flightNum
                  }`
            }`}</p>
            <div className="text-xs lg:text-sm font-medium">
              <p>
                Class:{" "}
                <span>
                  {journey.segments[0].bookingCode ===
                  journey.segments[journey.segments.length - 1].bookingCode
                    ? `${journey.segments[0].bookingCode}`
                    : `${journey.segments[0].bookingCode}, ${
                        journey.segments[journey.segments.length - 1]
                          .bookingCode
                      }`}
                </span>
              </p>
              <p>
                PNR: <span>{pnr}</span>
              </p>
              <p>
                Cabin:{" "}
                <span>
                  {journey.segments[0].cabinClass ===
                  journey.segments[journey.segments.length - 1].cabinClass
                    ? `${journey.segments[0].cabinClass}`
                    : `${journey.segments[0].cabinClass}, ${
                        journey.segments[journey.segments.length - 1].cabinClass
                      }`}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center w-[45%] pl-[1rem] lg:pl-0 lg:justify-between h-full">
          <div className="w-[10rem] h-full">
            <h2 className="text-base lg:text-lg font-bold">
              {journey.segments ? journey.segments[0].departure : "DEL"}
            </h2>
            <div className="text-xs lg:text-sm font-semibold">
              <p>
                {journey
                  ? `(${
                      Airport.filter(
                        (Airport) =>
                          Airport.IATA === journey.segments[0].departure
                      )[0].airportName
                    })`
                  : "(Indira Gandhi Airport, Delhi)"}
              </p>
              <p>
                Terminal:{" "}
                <span>
                  {journey.segments
                    ? journey.segments[0].departureTerminal
                    : "3"}
                </span>
              </p>
              <p>
                {journey
                  ? `${journey.departureDate}, ${journey.departureTime}`
                  : "07-Aug-2024 6:45 AM, Wed"}
              </p>
            </div>
          </div>
          <div className="w-[8rem] lg:w-[7rem] h-full flex items-center px-2">
            ---
            <IoAirplaneSharp
              className={`text-yellow-400 lg:w-[2rem] lg:h-[2rem]`}
            />
            ---
          </div>
          <div className="w-[12rem] h-full">
            <h2 className="text-base lg:text-lg font-bold">
              {journey.segments
                ? journey.segments[journey.segments.length - 1].arrival
                : "DEL"}
            </h2>
            <div className="text-xs lg:text-sm font-semibold">
              <p>
                {journey
                  ? `(${
                      Airport.filter(
                        (Airport) =>
                          Airport.IATA ===
                          journey.segments[journey.segments.length - 1].arrival
                      )[0].airportName
                    })`
                  : "(Indira Gandhi Airport, Delhi)"}
              </p>
              <p>
                Terminal:{" "}
                <span>
                  {journey.segments
                    ? journey.segments[journey.segments.length - 1]
                        .departureTerminal
                    : "3"}
                </span>
              </p>
              <p>
                {journey
                  ? `${journey.arrivalDate}, ${journey.arrivalTime}`
                  : "07-Aug-2024 9:10 AM, Wed"}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[10rem] h-full flex items-center text-lg font-bold lg:justify-center">
          Confirmed
        </div>
      </div>
    </div>
  );
};

export default StatusDetailsComponent;
