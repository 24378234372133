import React from "react";
import { NO_DATA_FOUND } from "../utils/icons";

const NoDataFoundComponent = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <img
        src={NO_DATA_FOUND}
        alt="NO_DATA_FOUND"
        className="w-[25rem] h-[25rem]"
      />
    </div>
  );
};

export default NoDataFoundComponent;
