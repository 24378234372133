import React from "react";

interface PassengerDetailsProp {
  passengers: any;
}

const PassengerDetailsComponent: React.FC<PassengerDetailsProp> = ({
  passengers,
}) => {
  return (
    <div className="bg-white w-full h-fit rounded-md my-4 shadow-md border-[1px]">
      <div className="w-full h-[3rem] text-sm md:text-base flex gap-[2rem] md:gap-[6rem] bg-black font-semibold text-white rounded-t-md px-4">
        <p className="w-1/3">Passenger Name</p>
        <p className="w-1/3">Passenger Type</p>
        <p className="w-1/3">E-Ticket No.</p>
      </div>
      {passengers.map((passenger: any, index: number) => (
        <div
          key={index}
          className="w-full h-fit gap-4 md:gap-[6rem] flex p-4 rounded-b-md"
        >
          <div className="w-1/3 font-bold text-xs md:text-base lg:text-lg">
            {passenger
              ? `${passenger.firstName} ${passenger.lastName}`
              : "Mr Ravi Subramanian"}
          </div>
          <div className="w-1/3 font-bold text-xs md:text-base lg:text-lg">
            {passenger.psgType === "ADT"
              ? "Adult"
              : passenger.psgType === "CHD"
              ? "Children"
              : passenger.psgType === "INF"
              ? "Infant"
              : "No Information"}
          </div>
          <div className="w-1/3 font-bold text-xs md:text-base lg:text-lg">
            {passenger.ticketNum}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PassengerDetailsComponent;
